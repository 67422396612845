package components

import efas.common.EfasColor
import emotion.react.css
import react.FC
import react.PropsWithClassName
import react.dom.html.ReactHTML.title
import react.dom.svg.ReactSVG.circle
import react.dom.svg.ReactSVG.g
import react.dom.svg.ReactSVG.svg
import react.dom.svg.ReactSVG.text
import web.cssom.*
import kotlin.math.PI

external interface DonutChartProps: PropsWithClassName {
    var title: String?
    var text: String?
    var size: Double?
    var pct: Double
    var strokeWidth: Double?
    var color: EfasColor?
}

val DonutChart = FC<DonutChartProps> { props ->
    val size = props.size ?: 100.0
    svg {
        width = size
        height = size
        viewBox = "0 0 $size $size"

        title {
            +props.title
        }

        circle {
            r = size / PI
            cy = size / 2
            cx = size / 2
            strokeWidth = (props.strokeWidth ?: 12.0)
            stroke = EfasColor.GREY.primary
            fill = "none"
        }

        circle {
            css(ClassName("dasharray_setup")) {
                this[Selector("strokeDashoffset")] = "${size / 2}"
                this[Selector("strokeDasharray")] = "${props.pct * 200} ${200 - (props.pct * 200)}"
            }
            r = size / PI
            cy = size / 2
            cx = size / 2
            strokeWidth = (props.strokeWidth ?: 12.0)
            stroke = props.color?.primary ?: EfasColor.GREEN.primary
            fill = "none"
        }

        props.text?.let {
            g {
                text {
                    x = size / 2
                    y = (size / 2) - (0.05 * size)
                    dominantBaseline = "middle"
                    textAnchor = "middle"
                    +it

                    fontFamily = "museo-component, Arial, Helvetica, sans-serif"
                    fontSize = 8.0
                }
                props.pct?.let {
                    text {
                        x = (size / 2)
                        y = (size / 2) + (0.05 * size)
                        dominantBaseline = "middle"
                        textAnchor = "middle"
                        +"${(it * 100).toString().take(5)}%"

                        fontFamily = "museo-component, Arial, Helvetica, sans-serif"
                        fontSize = 6.0
                    }
                }
            }
        }
    }
}