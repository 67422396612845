package interceptors

import efas.common.api.Client.Companion.json
import kotlinx.serialization.KSerializer
import org.reduxkotlin.middleware
import org.w3c.dom.Storage

/**
 * Middleware to record changes in local store
 */
inline fun<reified T : Any> localMiddleware(localStore: Storage, serializer: KSerializer<T>) = middleware<T> { store, next, action ->
    next(action)
    json.encodeToString(serializer, store.state).let {
        if (it != "{}") localStore.setItem("@${T::class.simpleName}", it)
    }
}

/**
 * Retrieve before default on session start; pass in as pre-loaded state
 */
inline fun<reified T : Any> fromLocal(localStore: Storage, serializer: KSerializer<T>) : T? =
    localStore.getItem("@${T::class.simpleName}")
        ?.let {
            try { json.decodeFromString(serializer, it)}
            // clear local data in case of serialization error
            catch(e: Throwable) {
                console.error("Unable to retrieve local state: ${e.message}" + "\nPurging local storage" + "\n${e.stackTraceToString()}")
                localStore.removeItem("@${T::class.simpleName}"); null
            }
        }