package efas.common

import efas.common.api.Hash.toLong
import efas.common.api.UUIDSerializer
import kotlinx.serialization.Serializable
import uuidparse
import uuidv4

/**
 * JS implementation of multiplatform UUID
 * (points to npm dependency 'Uuid')
 */

@Serializable(with = UUIDSerializer::class)
actual class UUID(actual override val mostSignificantBits: Long,
                  actual override val leastSignificantBits: Long) : MpUUID() {


    actual companion object {
        actual operator fun invoke(bytes: ByteArray) = UUID (
            bytes.take(8).toLong(),
            bytes.drop(8).toLong()
        )

        actual fun randomUUID(): UUID = fromString(uuidv4())

        actual fun fromString(name: String) = UUID(uuidparse(name))

        actual val NIL: UUID = UUID(0L, 0L)
    }

}
