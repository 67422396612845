package components.activity

import components.parts.Button
import efas.common.objects.Answer
import emotion.css.cx
import emotion.react.css
import react.ChildrenBuilder
import react.FC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.header
import react.dom.html.ReactHTML.span
import style.Layout
import web.cssom.ClassName
import web.cssom.Color
import web.cssom.px

val MultipleChoice = FC<ActProps> { props ->
    div {
        className = cx(Layout.columnEven, ClassName("layout_fill"), Layout.defaultPadding)
        header {
            css { color = Color(props.color.shadow) }
            + if (props.obj.content.contains("_")) "FILL IN THE BLANK" else "MULTIPLE CHOICE"
        }

        div {
            className = cx(Layout.rowFull, ClassName("wrap_elements"))

            interpolateBlank(
                splitNewlines(props.obj.content)
            )
        }

        div {
            css(Layout.rowEven, ClassName("wrap_elements"), Layout.defaultPadding) {
                borderRadius = 8.px
                backgroundColor = Color(props.color.highlight)
            }
            props.obj.possibilities.forEachIndexed { i, ans ->
                ans as Answer
                Button.Large {
                    color = props.color
                    text = ans.answer
                    onClick = {
                        if (props.checkResult(ans)) props.onNext(ans)
                    }
                    key = "multiple_choice-$i"
                }
            }
        }
    }
}

fun ChildrenBuilder.interpolateBlank(src: String) {
    if (src.contains("_")) {
        val chunks = src.split("_+".toRegex())
        chunks.forEachIndexed { index, s ->
            h1 { +s }
            if (index < chunks.size - 1)
                span { className = ClassName("blank_space") }
        }
    }
    else h1 { +src }
}

fun ChildrenBuilder.splitNewlines(src: String) : String {
    val pattern = "(:|\\.)".toRegex()
    val delim = pattern.find(src)?.groupValues?.firstOrNull()

    return if (delim != null) {
        val sentences = pattern.split(src)
        val last = sentences.last()

        sentences.forEach { s ->
            if (s != last) h1 { +"$s$delim" }
        }

        last
    } else src
}
