package components.parts

import efas.common.EfasColor
import emotion.react.css
import react.FC
import react.PropsWithClassName
import react.dom.events.MouseEventHandler
import react.dom.html.ReactHTML.div
import style.*
import web.cssom.ClassName
import web.cssom.Color

enum class ViewState {
    LISTEN, PLAY, INSTRUCTION, HELP
}

external interface FooterProps : PropsWithClassName {
    var color: EfasColor?
    var onInstruction: MouseEventHandler<*>?
    var onHelp: MouseEventHandler<*>?
    var onListen: MouseEventHandler<*>?
    var onForward: MouseEventHandler<*>?
    var onBack: MouseEventHandler<*>?
    var viewState: ViewState?
}

val Footer = FC<FooterProps> { props ->
    div {
        css(ClassName("footer"), props.className) {
            backgroundColor = Color(props.color?.primary ?: EfasColor.GREEN.primary)
        }

        Button.CircleFlat {
            icon = rewind
            color = props.color
            disabled = (props.onBack == null)
            props.onBack?.let { onClick = it }
        }

        Button.CircleFlat {
            icon = letter
            color = props.color
            selected = props.viewState == ViewState.INSTRUCTION
            disabled = (props.onInstruction == null)
            onClick = props.onInstruction
        }

        Button.CircleFlat {
            icon = question
            color = props.color
            selected = props.viewState == ViewState.HELP
            disabled = (props.onHelp == null)
            onClick = props.onHelp
        }

        Button.CircleFlat {
            icon = eighthNote
            color = props.color
            selected = props.viewState == ViewState.LISTEN
            disabled = (props.onListen == null)
            onClick = props.onListen
        }

        Button.CircleFlat {
            icon = fastForward
            color = props.color
            disabled = (props.onForward == null)
            props.onForward?.let { onClick = it }
        }
    }
}