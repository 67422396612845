package components.song

import Application.store
import Application.user
import components.parts.TextInput
import components.parts.wrapLoading
import efas.common.objects.ContentType
import emotion.react.css
import hooks.useStore
import prototype.ApiAction
import prototype.LocalAction
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.header
import react.useEffectOnce
import react.useRef
import style.Layout
import web.cssom.Color
import web.cssom.rem
import web.html.HTMLAudioElement

val SelectSong = FC<Props> {
    val nowPlaying = useRef<HTMLAudioElement>()
    val state = useStore(store)
    val user = useStore(user)

    useEffectOnce {
        store.dispatch(ApiAction.Refresh(ContentType.SONG))
        store.dispatch(ApiAction.Refresh(ContentType.GAME))
    }

    div {
        css(Layout.innerFull) {
            gap = 2.rem
            backgroundColor = Color(user.colorScheme.highlight)
            color = Color(user.colorScheme.shadow)
        }

        header { +"SELECT A SONG" }

        TextInput { isSearch = true }

        div {
            className = Layout.scrollContainer

            wrapLoading(
                state.loading || state.song.inventory.isEmpty(),
                state.song.inventory
            ) { songs ->
                songs.forEach {
                    SongItem {
                        song = it
                        color = user.colorScheme
                        key = it.hashCode().toString()
                        onPlay = {
                            nowPlaying.current?.pause()
                            nowPlaying.current = it.apply { currentTime = 60.0 }
                        }
                    }
                }
            }
        }
    }
}