
package hooks

import org.reduxkotlin.Store
import react.useEffectOnce
import react.useState

fun<T : Any> useStore(store: Store<T>): T {
    var state by useState(store.state)

    useEffectOnce {
        val unsub = store.subscribe { state = store.state }
        cleanup { unsub() }
    }

    return state
}