package prototype

import efas.common.objects.Game
import efas.common.objects.Send
import efas.common.objects.Song
import efas.common.objects.Test
import kotlinx.serialization.Serializable


@Serializable
data class State (
    val test: Domain<Test> = Domain(),
    val game: Domain<Game> = Domain(),
    val song: Domain<Song> = Domain(),
    val displayTimeout: Long = 2000L,
    val loading: Boolean = false,
)

@Serializable
data class Domain<T : Send> (val item: T? = null,
                             val inventory: List<T> = listOf())