package model.user

import efas.common.EfasColor
import efas.common.objects.Answerable
import efas.common.objects.Progress
import efas.common.objects.Result
import efas.common.objects.User
import kotlinx.serialization.Serializable
import kotlin.random.Random

@Serializable
data class UserState(val user: User? = null,
                     val progress: Progress = Progress().apply {
                           set(progressMap.keys.associateWith { Random.nextInt(0, 4) })
                     },
                     val results: List<Result> = listOf(),
                     val currentResult: List<Answerable> = listOf(),
                     val colorScheme: EfasColor = EfasColor.GREEN)