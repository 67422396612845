package components.parts

import emotion.css.cx
import emotion.react.css
import react.ChildrenBuilder
import react.FC
import react.PropsWithClassName
import style.icon
import web.cssom.ClassName

inline fun <T> ChildrenBuilder.wrapLoading(loading: Boolean, item: T?, inner: ChildrenBuilder.(T)->Unit) {
    if (loading) Spinner()
    else item?.let { inner(it) } ?: Spinner()
}

val Spinner = FC<PropsWithClassName> {
    icon("spinner") {
        className = cx(ClassName("animation_spin"), ClassName("icon_normalize"), ClassName("icon_center"), it.className)
    }
}