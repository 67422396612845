package style

import react.ChildrenBuilder
import react.dom.html.HTMLAttributes
import react.dom.html.ReactHTML.span
import web.cssom.ClassName
import web.html.HTMLSpanElement
fun ChildrenBuilder.icon(icon: String, op: HTMLAttributes<HTMLSpanElement>.()->Unit = {}): Unit =
    span {
        op()
        className = ClassName("fas fa-$icon $className")
    }


