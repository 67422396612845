package model.user

import efas.common.EfasColor
import efas.common.objects.Answer
import efas.common.objects.Answerable
import efas.common.objects.Result
import efas.common.objects.User
import kotlinx.browser.window
import prototype.LocalAction

fun userRootReducer(state: UserState, action: Any) : UserState =
    when(action) {
        is LocalAction.Add<*> -> when(action.obj) {
            is Answerable ->
                state.currentResult.let { state.copy(currentResult = it + action.obj as Answer) }
            is Result ->
                state.copy(results = state.results + action.obj as Result)
            else -> state
        }
        is LocalAction.Replace<*> -> when(action.obj) {
            is EfasColor -> state.copy(colorScheme = (action.obj as EfasColor))
            is User -> state.copy(user = action.obj as User)
            else -> state
        }
        is LocalAction.Clear -> UserState(user = state.user)
        else -> state
    }