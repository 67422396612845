package components.parts


import efas.common.EfasColor
import emotion.css.cx
import emotion.react.css
import react.FC
import react.MutableRefObject
import react.PropsWithClassName
import react.StateInstance
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import style.*
import web.cssom.ClassName
import web.cssom.Color
import web.html.HTMLAudioElement

external interface PlaybackProps : PropsWithClassName {
    var audio: MutableRefObject<HTMLAudioElement>
    var isPlaying: StateInstance<Boolean>
    var color: EfasColor?
}

val Playback = FC<PlaybackProps> { props ->
    var isPlaying by props.isPlaying

    div {
        className = cx(Layout.rowFull, ClassName("playback"))
        button {
            name = "rewind 10 seconds"
            css(ClassName("rew_ff")) {
                color = Color(props.color?.shadow ?: EfasColor.BLACK)
                active {
                    color = Color(props.color?.primary ?: EfasColor.BLUE.primary)
                }
            }
            rewind.component {
                fill = props.color?.shadow ?: EfasColor.BLACK
                alt = "rewind 10 seconds icon"
            }
            p {
                className = ClassName("font_descriptor")
                +"10"
            }
            onClick = {
                props.audio.current?.apply {
                    currentTime -= 10.0
                }
            }
        }

        button {
            if (!isPlaying) {
                name = "play"
                play.component {
                    fill = props.color?.shadow ?: EfasColor.BLACK
                    alt = "play icon"
                }
                onClick = {
                    props.audio.current?.play()
                    isPlaying = !isPlaying
                }
            } else {
                name = "pause"
                pause.component {
                    fill = props.color?.shadow ?: EfasColor.BLACK
                    alt = "pause icon"
                }
                onClick = {
                    props.audio.current?.pause()
                    isPlaying = !isPlaying
                }
            }
        }

        button {
            name = "fast forward 10 seconds"
            css(ClassName("rew_ff")) {
                color = Color(props.color?.shadow ?: EfasColor.BLACK)
                active {
                    color = Color(props.color?.primary ?: EfasColor.BLUE.primary)
                }
            }
            fastForward.component {
                fill = props.color?.shadow ?: EfasColor.BLACK
                alt = "fast forward 10 seconds icon"
            }
            p {
                className = ClassName("font_descriptor")
                +"10"
            }
            onClick = {
                props.audio.current?.apply {
                    currentTime += 10.0
                }
            }
        }
    }
}