package components.activity

import components.parts.Spinner
import efas.common.EfasColor
import efas.common.objects.*
import prototype.Callback
import react.*

external interface ActivitySequenceProps: PropsWithClassName {
    var activities: List<Activity>
    var index: StateInstance<Int>
    var checkAnswer: (Activity, Answer)->Boolean
    var onDone: Callback
//    var onResult: (Answer)->Unit
    var color: EfasColor?
}

val ActivitySequence = FC<ActivitySequenceProps> { props ->
    var currentIndex by props.index
    var currentAct by useState(props.activities.first())
    var isLast by useState(false)

    useEffect(currentIndex) {
        props.activities.getOrNull(currentIndex)
            ?.let { currentAct = it }
        if (currentIndex == props.activities.size - 1) isLast = true
    }

    when (currentAct) {
        is MultipleChoice ->
            MultipleChoice {
                obj = currentAct
                onNext = if (!isLast) { _ -> currentIndex++ } else { _ -> props.onDone() }
                checkResult = { props.checkAnswer(currentAct, it) }
                color = props.color ?: EfasColor.BLUE
            }
        is SelectAll ->
            SelectAll {
                obj = currentAct
                onNext = if (!isLast) { _ -> currentIndex++ } else { _ -> props.onDone() }
                checkResult = { props.checkAnswer(currentAct, it) }
                color = props.color ?: EfasColor.BLUE
            }
        is Sort ->
            BucketSort {
                obj = currentAct
                onNext = if (!isLast) { _ -> currentIndex++ } else { _ -> props.onDone() }
                checkResult = { props.checkAnswer(currentAct, it) }
                color = props.color ?: EfasColor.BLUE
            }

        else -> Spinner()
    }
}


//val ActSequenceA = fc<ActSeqProps> { props ->
//    var currentIndex by useState(0)
//    var currentAct by useState<Activity>()
//    var results by useState<List<Answerable>>(listOf())
//
//    useEffect(currentAct) { currentAct?.let { props.linkCurrent(it) } }
//
//    useEffect(currentIndex) {
//        when(val current = props.obj) {
//            is Test ->
//                if (currentIndex < current.questions.size) currentAct = current.questions[currentIndex]
//                else props.done(results)
//            is Game ->
//                if (currentIndex < current.activities.size) currentAct = current.activities[currentIndex]
//                else props.done(results)
//            else -> error("Invalid Type")
//        }
//    }
//
//    useEffect(props.index) {
//        props.index?.let { currentIndex = it }
//    }
//
//    useEffect(props.obj) {
//        when (props.obj) {
//            is Game -> (props.obj as Game).activities.first()
//            is Test -> (props.obj as Test).questions.first()
//            else -> null
//        }?.let { currentAct = it }
//    }
//
//
//    val validate: (Answer) -> Boolean = {
////        Store current result (only keep the first one)
//        results.getOrNull(currentIndex) ?: run { results += it }
//
////        verify answer
//        currentAct?.let { act ->
//            props.validate(act, it)
//        }  ?: false
//    }
//
//    val next: ()->Unit = { currentIndex++ }
//
//    when(currentAct) {
//        is MultipleChoice -> MultipleChoice {
//            attrs {
//                obj = currentAct as MultipleChoice
//                submitResult = validate
//                this.next = next
//            }
//        }
//        is SelectAll -> SelectAll {
//            attrs {
//                obj = currentAct as SelectAll
//                submitResult = validate
//                this.next = next
//            }
//        }
//        is Sort -> Sort {
//            attrs {
//                obj = currentAct as Sort
//                submitResult = validate
//                this.next = next
//            }
//        }
//
//        else -> LoadingSpinner(EfasColor.BLACK)
//    }
//}




//inline fun<reified T: Content> ActSequenceOld() = fc<Props> {
//    var currentAct by useState<Activity>()
//    val state = useStore(store)
//    val nav = useNavigate()
//
//    val playback = store.state.game.item?.song?.let { current ->
//        (store.state.song.inventory as List<Song>).firstOrNull { it.id == current }
//    }
//    val (audio,clock) = useAudio(playback)
//
//    useEffect(state.test.item) {
//        currentAct =
//    }
//
//    var next: ()->Unit = {}
//
//    val isGame = T::class == Game::class
//
//    if (isGame) {
//        useEffect(state) {
//            (state.game.inventory.randomOrNull() as? MetaData)?.let {
//                store.dispatch(DownloadObject<T>(it.id))
//            }
//            state.game.item?.let { currentAct = it.activities.firstOrNull() }
//        }
//
//        state.game.item?.let {
//            it.activities.getOrNull(it.activities.indexOf(currentAct) + 1)?.let {
//                next = { currentAct = it }
//            } ?: run {
//                user.dispatch(Publish<T>(state.game.item!!.id))
//                nav("/menu")
//            }
//        }
//    } else {
//        useEffect(state) {
//            (state.test.inventory.randomOrNull() as? MetaData)?.let {
//                store.dispatch(DownloadObject<T>(it.id))
//            }
//            state.test.item?.let { currentAct = it.questions.firstOrNull() }
//        }
//        state.test.item?.let {
//            it.questions.getOrNull(it.questions.indexOf(currentAct) + 1)?.let {
//                next = { currentAct = it }
//            } ?: run {
//                user.dispatch(Publish<T>(state.game.item!!.id))
//                nav("/menu")
//            }
//        }
//    }
//
//    val propagateResult: (Answerable)->Unit = { user.dispatch(AddAnswer(it)) }
//
//    when(currentAct) {
//        is MultipleChoice -> MultipleChoice {
//            attrs {
//                this.expectSuccess = isGame
//                obj = currentAct as MultipleChoice
//                this.next = next
//                resultCallback = propagateResult
//                key = currentAct.hashCode().toString()
//            }
//        }
//        is efas.common.objects.SelectAll -> child(SelectAll::class) {
//            attrs {
//                this.expectSuccess = isGame
//                obj = currentAct as Activity
//                this.next = next
//                resultCallback = propagateResult
//                key = currentAct.hashCode().toString()
//            }
//        }
//        is efas.common.objects.Sort -> child(Sort::class) {
//            attrs {
//                this.expectSuccess = isGame
//                obj = currentAct as Activity
//                this.next = next
//                resultCallback = propagateResult
//            }
//        }
////        is efas.common.objects.Prompt -> styledDiv {
////            css {
////                +Layout.flexColumnCenter
//////                padding(5.rem)
////            }
////            h1 {
////                +(currentAct as Prompt).instruction
////                attrs.onClick = { supervisor.cancelChildren(); next() }
////            }
////        }.also { scope.launch { delay(state.displayTimeout); next() } }
//
//        else -> LoadingSpinner(EfasColor.BLACK)
//    }
//
//    if (isGame) PlaybackNav {
//        attrs {
//        }
//    }
//
//}