package efas.common.objects

import efas.common.UUID
import efas.common.api.Client
import efas.common.api.Error
import efas.common.api.Message
import efas.common.baseURL
import io.ktor.client.call.*
import io.ktor.client.request.*
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Union type package for user generated data. Applies to games or tests or groups thereof.
 *
 */

@Serializable
@SerialName("Result")
data class Result (val test: UUID? = null,
                   val game: UUID? = null,
                   val answers: List<Answerable>,
                   val user: UUID = UUID.NIL) : Send {

    companion object {
        inline operator fun <T : Any?> invoke(op: API.() -> T) : T = API.op()
    }

    object API : Client("$baseURL/results") {
        override suspend fun inventory(userId: UUID): Message =
            try { client.get("${path}/$userId").body() }
            catch (e: Throwable) { Error(e.message) }
    }
}
