package prototype

import interceptors.apiMiddleware
import interceptors.localMiddleware
import kotlinx.browser.window
import kotlinx.coroutines.Job
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.plus
import org.reduxkotlin.applyMiddleware
import org.reduxkotlin.threadsafe.createThreadSafeStore

abstract class EfasCore {
    val store = createThreadSafeStore(
        ::rootReducer,
        State(),
        applyMiddleware(apiMiddleware(scope), localMiddleware(window.localStorage, State.serializer()))
    )

    companion object {
        val supervisor: Job = SupervisorJob()
        val timeoutSupervisor: Job = SupervisorJob()
        val audioSupervisor: Job = SupervisorJob()
        val scope = MainScope() + supervisor
        val audioScope = scope + audioSupervisor
    }
}