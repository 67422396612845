package components.song

import components.parts.Playback
import components.parts.Spinner
import efas.common.EfasColor
import emotion.react.css
import hooks.useAudioWithClock
import react.FC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.h4
import react.dom.html.ReactHTML.header
import react.dom.html.ReactHTML.hr
import react.useEffect
import react.useState
import style.Layout
import web.cssom.*
import web.cssom.Position.Companion.sticky

val Listen = FC<SongProps> {props ->
    val theme by useState(props.color ?: EfasColor.GREEN)

    val (audioRef, isPlayingState, timeState) = useAudioWithClock(props.song)

    useEffect(audioRef.current) { audioRef.current?.onended = props.onEnd }

    div {
        css(Layout.innerEven) {
            backgroundColor = Color(theme.highlight)
            color = Color(theme.shadow)
        }

        if (props.showHeader == true) header { +"LISTENING CHALLENGE" }

        props.song?.let { song ->
            h1 { +song.artist }
            h4 { +song.title }
            hr {
                css(ClassName("divider")) {
                    borderColor = Color(theme.shadow)
                }
            }
            div {
                className = ClassName("lyric_view")
                song.lyrics.forEach { lyric ->
                    LyricItem {
                        this.lyric = lyric
                        this.timeState = timeState
                        color = theme
                    }
                }
                div {
                    // blend bottom of lyric container w/ opacity gradient
                    css {
                        position = sticky
                        bottom = -4.px
                        width = 100.pct
                        height = 10.vh
                        this[Selector("background")] = """
                        linear-gradient(
                          to bottom, 
                          rgba(0, 0, 0, 0), 
                          ${theme.highlight}
                        );
                    """.trimIndent()
                    }
                }
            }
            Playback {
                audio = audioRef
                isPlaying = isPlayingState
                color = theme
            }
        } ?: Spinner()
    }
}