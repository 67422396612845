package components.progress

import Application.user
import components.DonutChart
import components.parts.Button
import efas.common.Category
import emotion.css.cx
import emotion.react.css
import hooks.useStore
import kotlinx.browser.window
import normalize
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h4
import react.dom.html.ReactHTML.header
import style.Layout
import web.cssom.*
import kotlin.random.Random

val ProgressComponent = FC<Props> {
    val user = useStore(user)
    var percentages by useState(Category.SkillGroup.values().associateWith { 0.0 })

    useEffectOnce {
        percentages = user.progress.progressMap.entries
            .groupBy { it.key.group }
            .map { (skill, categories) ->
                skill to (categories.sumOf { it.value }.div(categories.size.toDouble() * 3))
            }.toMap()
    }

    div {
        css(Layout.innerFull) {
            gap = 2.rem
            backgroundColor = Color(user.colorScheme.highlight)
            color = Color(user.colorScheme.shadow)
        }

        header { +"MY PROGRESS" }

        Category.SkillGroup.values().forEach { skill ->
            div {
                className = cx(Layout.rowFull, Layout.defaultPadding)
                DonutChart {
                    title = skill.name
                    text = skill.name
                    size = 100.0
                    pct = percentages[skill]!!
                    key = skill.name
                }

                div {
                    className = cx(ClassName("progress_detail"), Layout.defaultPadding)
                    user.progress.progressMap
                        .filter { it.key.group == skill }
                        .forEach {
                            div {
                                className = cx(Layout.rowFull)
                                h4 { +it.key.name.normalize() }

                                div {
                                    className = cx(Layout.rowEven, ClassName("level_group"))
                                    when(it.value) {
                                        3 -> {
                                            div { className = ClassName("badge_beginner") }
                                            div { className = ClassName("badge_intermediate") }
                                            div { className = ClassName("badge_advanced") }
                                        }

                                        2 -> {
                                            div { className = ClassName("badge_beginner") }
                                            div { className = ClassName("badge_intermediate") }
                                            repeat(1) {
                                                div {
                                                    css(ClassName("empty_circle")) {
                                                        backgroundColor = Color(user.colorScheme.primary)
                                                    }
                                                }
                                            }
                                        }
                                        1 -> {
                                            div { className = ClassName("badge_beginner") }
                                            repeat(2) {
                                                div {
                                                    css(ClassName("empty_circle")) {
                                                        backgroundColor = Color(user.colorScheme.primary)
                                                    }
                                                }
                                            }
                                        }
                                        0 -> repeat(3) {
                                            div {
                                                css(ClassName("empty_circle")) {
                                                    backgroundColor = Color(user.colorScheme.primary)
                                                }
                                            }
                                        }
                                    }

                                }
                            }
                        }
                }
            }
        }

        Button.Wide {
            text = "See Test Results"
            color = user.colorScheme
            outline = true
        }

    }
}
//
//external interface LevelDisplayProps : Props {
//    var group: String
//    var categories: List<String>
//}
//val LevelDisplay = fc<LevelDisplayProps> { props ->
//    val (isOpen, setIsOpen) = useState(false)
//    styledDiv {
//        css {
//            display = Display.flex
//            justifyContent = JustifyContent.spaceBetween
//            width = 90.vw
//            backgroundColor = Color(when(props.group) {
//                "Grammar" -> EfasColor.BLUE.primary
//                "Sentences" -> EfasColor.RED.primary
//                "Vocabulary" -> EfasColor.YELLOW.primary
//                "Comprehension" -> EfasColor.PINK.primary
//                else -> ""
//            })
//        }
//        styledH2 {
//            css {
//                padding = Padding (1.rem)
//                margin = Margin(0.px)
//            }
//            attrs.key = props.group.hashCode().toString()
//            +props.group.normalize()
//        }
////        styledIcon(if(isOpen) "chevron-down" else "chevron-right") {
////            css {
////                fontSize = 3.rem
////                marginRight = 2.rem
////                alignSelf = Align.center
////            }
////        }
//        attrs {
//            onClickFunction = {
//                setIsOpen(!isOpen)
//            }
//        }
//    }
//
//    if (isOpen) {
//        styledUl {
//            css {
//                listStyleType = ListStyleType.none
//            }
//            props.categories.forEach {
//                styledLi {
//                    css {
//                        display = Display.flex
//                        children {
//                            margin = Margin(0.px)
//                        }
//                    }
//                    attrs.key = it.hashCode().toString()
//                    styledDiv {
//                        css {
//                            width = 80.vw
//                            display = Display.flex
//                            alignItems = Align.center
//                            justifyContent = JustifyContent.spaceBetween
//                        }
//                        styledDiv {
//                            css {
//                                width = 12.vw
//                                display = Display.flex
//                                justifyContent = JustifyContent.spaceBetween
//                                children {
//                                    color = Color(EfasColor.GREEN.primary)
//                                }
//                            }
////                            repeat(3) { icon("square") {} }
//                        }
//
//                        p {
//                            +it.normalize()
//                        }
//                    }
//                }
//            }
//        }
//    }
//}
