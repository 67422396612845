package components.activity

import components.parts.Button
import efas.common.EfasColor
import efas.common.objects.Answer
import efas.common.objects.ResultAnswer
import efas.common.objects.SelectAll
import emotion.css.cx
import emotion.react.css
import react.FC
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.header
import react.useState
import style.Layout
import web.cssom.ClassName
import web.cssom.Color
import web.cssom.JustifyContent.Companion.center

val SelectAll = FC<ActProps> { props ->
    props.obj as SelectAll
    var selected by useState<List<Answer>>(listOf())

    div {
        className = cx(Layout.columnEven, Layout.defaultPadding, ClassName("layout_fill"))
        header {
            css { color = Color(props.color.shadow) }
            +"WORD FINDER"
        }
        h1 {
            +props.obj.content
        }
        div {
            css(Layout.rowCenter, Layout.defaultPadding, ClassName("wrap_elements")) {
                justifyContent = center
            }
            props.obj.possibilities.forEachIndexed { i, ans ->
                ans as Answer
                button {
                    css(ClassName("button_large")) {
                        if (selected.contains(ans)) backgroundColor = Color(props.color.shadow)
                        else backgroundColor = Color(props.color.primary)
                    }

                    +ans.answer

                    onClick = {
                        if (selected.contains(ans)) selected = selected - ans
                        else selected = selected + ans
                    }
                    key = "select_ans-$i"
                }
            }
        }
        Button.Large {
            text = "Check Answer"
            color = EfasColor.GREEN
            onClick = {
                val currentResult = props.obj.unsafeCast<SelectAll>().merge(ResultAnswer(selected))
                if(props.checkResult(currentResult)) props.onNext(currentResult)
            }
        }
    }
}