@file:OptIn(ExperimentalSerializationApi::class)
package efas.common

import efas.common.api.Hash.toByteArray
import efas.common.api.UUIDSerializer
import efas.common.api.toHexString
import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.Serializable

/**
 * Simplified UUID declaration for multiplatform use
 *
 * Version 4 (randomly generated) variant 2 (Leach-Salz) are the only methods made available
 *
 * Each respective 'actual' class is used as a wrapper for platform specific libraries
 *
 */


/**
 * Source class containing multiplatform code to pass down
 * to platform specific implementations
 *
 */
@Serializable(with = UUIDSerializer::class)
abstract class MpUUID() : Comparable<UUID> {
    abstract val mostSignificantBits: Long
    abstract val leastSignificantBits: Long

    fun getBytes() : ByteArray = (mostSignificantBits.toByteArray() + leastSignificantBits.toByteArray())

    override fun equals(other: Any?): Boolean = (other as? UUID)?.let {
        mostSignificantBits == it.mostSignificantBits && leastSignificantBits == it.leastSignificantBits
    } ?: false

    override fun hashCode(): Int = (mostSignificantBits xor leastSignificantBits)
        .let { ((it shr 32) xor it).toInt() }

    override fun compareTo(other: UUID): Int {
        return when {
            mostSignificantBits < other.mostSignificantBits -> -1
            mostSignificantBits > other.mostSignificantBits -> 1
            leastSignificantBits < other.leastSignificantBits -> -1
            leastSignificantBits > other.leastSignificantBits -> 1
            else -> 0
        }
    }

    fun isNilUUID() : Boolean = this == UUID.NIL

    override fun toString() : String {
        return (mostSignificantBits.toByteArray() + leastSignificantBits.toByteArray())
            .toHexString()
            .let {
                listOf(
                    it.subSequence(0..7),
                    '-',
                    it.subSequence(8..11),
                    '-',
                    it.subSequence(12..15),
                    '-',
                    it.subSequence(16..19),
                    '-',
                    it.subSequence(20..31),
                ).joinToString("")
            }
    }

}

/**
 * Expect class to allow for platform specific implementations
 * (mostly for delegating parsing and generation to libraries)
 *
 */

@Serializable(with = UUIDSerializer::class)
expect class UUID : MpUUID {
    override val mostSignificantBits: Long
    override val leastSignificantBits: Long

    companion object {
//        require constructor overload for building with just ByteArray
        operator fun invoke(bytes: ByteArray) : UUID
        fun randomUUID() : UUID
        fun fromString(name: String) : UUID
        val NIL: UUID
    }
}