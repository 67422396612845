package efas.common.api

import efas.common.api.PlatformHash.fromBase64
import efas.common.api.PlatformHash.toBase64

//  Common efas.common.api.api.seed
internal const val seed = 56119

expect object PlatformHash {
    fun String.sha256() : ByteArray

    fun String.md5() : ByteArray

    fun String.fromBase64() : ByteArray

    fun ByteArray.toBase64() : String
}

/**
 * Helper functions for low level data encoding;
 * Platform specific implementations through PlatformHash object
 */

object Hash {
    fun Long.toByteArray(): ByteArray =
        ByteArray(8) { ( this shr (8 * it)).toByte() }.reversedArray()

    fun List<Byte>.toLong() : Long {
        require(this.size == 8) { "list must have 8 bytes to be converted to long" }
        return this.reversed().foldIndexed(0L) { i, acc, b ->
            acc or ((b.toLong() and 0xff) shl (8 * i))
        }
    }

    fun Int.toByteArray(): ByteArray =
        ByteArray(4) { ( this shr (8 * it)).toByte() }.reversedArray()

    fun List<Byte>.toInt() : Int {
        require(this.size == 4) { "list must have 4 bytes to be converted to int" }
        return this.reversed().foldIndexed(0) { i, acc, b ->
            acc or ((b.toInt() and 0xff) shl (8 * i))
        }
    }

    fun String.base64Encode(): String = this.encodeToByteArray().toBase64()

    fun String.base64Decode() : String = this.fromBase64().decodeToString()
}

private val hex = "0123456789ABCDEF".toCharArray()

fun ByteArray.toHexString() : String {
    return this.foldIndexed(CharArray(this.size * 2)) { i, acc, b ->
        (b.toInt() and 0xff).let {
            acc.apply {
                this[i*2] = hex[it ushr 4]
                this[(i*2)+1] = hex[it and 0x0f]
            }
        }
    }.joinToString("").toLowerCase()
}

fun String.toBytesRadix16() : ByteArray = this.chunked(2).map { it.toInt(16).toByte() }.toByteArray()