package components.activity

import Application.store
import Application.user
import components.parts.wrapLoading
import efas.common.objects.ContentType
import hooks.useStore
import prototype.ApiAction
import react.*
import react.router.useNavigate

val TestComponent = FC<Props> {
    val state = useStore(store)
    val user = useStore(user)
    val nav = useNavigate()

    val sequenceIndexState = useState(0)

    useEffectOnce { store.dispatch(ApiAction.Refresh(ContentType.TEST)) }

    useEffect(state.test.inventory) {
        state.test.inventory
            .filter { it.isActive }
            .randomOrNull()
            ?.let { store.dispatch(ApiAction.Download(it.id, ContentType.TEST)) }
    }

    wrapLoading(state.loading, state.test.item) { test ->
        ActivitySequence {
            index = sequenceIndexState
            checkAnswer = { _,_ -> true }
            onDone = { nav("/") }
            color = user.colorScheme
            activities = test.questions
        }
    }
}