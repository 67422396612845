package components.song

import efas.common.EfasColor
import efas.common.objects.Lyric
import emotion.react.css
import react.*
import react.dom.html.ReactHTML.p
import web.cssom.Color
import web.cssom.pct
import web.html.HTMLParagraphElement
import kotlin.time.DurationUnit

external interface LyricProps : Props {
    var lyric: Lyric
    var timeState: StateInstance<Double>
    var color: EfasColor?
}

val LyricItem = FC<LyricProps> { props ->
    val element = useRef<HTMLParagraphElement>()
    val clock by props.timeState
    var isMatch by useState(false)

    useEffect(clock) {
        isMatch =
            clock >= (props.lyric.timeIn?.toDouble(DurationUnit.SECONDS) ?: Double.MAX_VALUE)
                    && clock < (props.lyric.timeOut?.toDouble(DurationUnit.SECONDS) ?: 0.0)
    }

    useLayoutEffect { if (isMatch) element.current?.scrollIntoView() }

    p {
        +props.lyric.text
        ref = element
        css {
            if (isMatch){
                color = Color(props.color?.primary ?: EfasColor.GREY.primary)
                fontSize = 110.pct
            }
        }
    }
}