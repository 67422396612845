package interceptors

import efas.common.objects.*
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import org.reduxkotlin.Store
import org.reduxkotlin.middleware
import prototype.ApiAction
import prototype.LocalAction
import prototype.State

/**
 * API Middleware:
 *  - Glue functions for connecting api actions to react components
 *  - Intercepts certain action types to perform side-effects in the background (comparable to thunk)
 */

fun apiMiddleware(scope: CoroutineScope) = middleware<State> { store, next, action ->
    if (action is ApiAction) when (action) {
        is ApiAction.Delete -> scope.deleteObject(action, store)
        is ApiAction.Download -> scope.downloadObject(action, store)
        is ApiAction.Refresh -> scope.loadInventories(action,store)
        is ApiAction.Upload -> scope.uploadObject(action, store)
        is ApiAction.Change<*> -> scope.changeObject(action, store)
    }
    else if (action is LocalAction) action.callback?.invoke()
    next(action)
}

fun CoroutineScope.loadInventories(action: ApiAction.Refresh, store: Store<State>) =
    asyncWithLoading(store) {
        when (action.type) {
            ContentType.SONG -> Song { inventory() }
            ContentType.GAME -> Game { inventory() }
            ContentType.TEST -> Test { inventory() }
        }.content<Inventory> { store.dispatch(LocalAction.Import(it, action.callback)) }
            .error { console.error("Unable to load ${action.type.marker.simpleName} inventory\n    $it") }
    }

fun CoroutineScope.downloadObject(action: ApiAction.Download, store: Store<State>) =
    asyncWithLoading(store) {
        when (action.type) {
            ContentType.SONG -> Song { get(action.id) }
            ContentType.GAME -> Game { get(action.id) }
            ContentType.TEST -> Test { get(action.id) }
        }.content<Content> { store.dispatch(LocalAction.Import(it, action.callback)) }
            .error { console.error("Unable to retrieve ${action.type.marker.simpleName}\n    $it") }
    }


fun CoroutineScope.uploadObject(action: ApiAction.Upload, store: Store<State>) =
    asyncWithLoading(store) {
        when (action.type) {
            ContentType.SONG -> TODO()
            ContentType.GAME -> Game {
                store.state.game.item?.let {
                    if (action.force) put(it) else post(it)
                }
            }
            ContentType.TEST -> Test {
                store.state.test.item?.let {
                    if (action.force) put(it) else post(it)
                }
            }
        }?.ok { action.callback?.let { it() } }
            ?.error { window.alert("Unable to upload ${action.type.marker.simpleName}\n    $it") }
            ?: console.error("no item to upload")
    }

fun CoroutineScope.deleteObject(action: ApiAction.Delete, store: Store<State>) = if (
    window.confirm(
        """
            This will permanently delete
                ${action.type.marker.simpleName}:${action.id}
            and its associated media. Continue?
        """.trimIndent()
    )) asyncWithLoading(store) {
        when(action.type) {
            ContentType.SONG -> Song { delete(action.id) }
            ContentType.GAME -> Game { delete(action.id) }
            ContentType.TEST -> Test { delete(action.id) }
        }.ok { store.dispatch(ApiAction.Refresh(action.type, action.callback)) }
            .error(console::error)

    } else Unit

fun CoroutineScope.changeObject(action: ApiAction.Change<*>, store: Store<State>) =
    if(action.prop.name == Public::isActive.name && action.value is Boolean)
        asyncWithLoading(store) {
            when(action.type) {
                ContentType.SONG -> Song { setEnabled(action.id, action.value) }
                ContentType.GAME -> Game { setEnabled(action.id, action.value) }
                ContentType.TEST -> Test { setEnabled(action.id, action.value) }
            }.ok { store.dispatch(ApiAction.Refresh(action.type, action.callback)) }
                .error(window::alert)
        }
    else Unit

fun<T: Any> CoroutineScope.asyncWithLoading(store: Store<T>, op: suspend ()->Unit) {
    store.dispatch(LocalAction.Loading)
    launch {
        op()
        store.dispatch(LocalAction.Loading)
    }
}