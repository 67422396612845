package components.parts

import emotion.css.cx
import react.ChildrenBuilder
import react.FC
import react.PropsWithClassName
import react.dom.html.InputHTMLAttributes
import react.dom.html.ReactHTML.input
import web.cssom.ClassName
import web.html.HTMLInputElement

external interface TextInputProps : PropsWithClassName {
    var isSearch: Boolean?
}

val TextInput = FC<TextInputProps> {
    input {
        className = cx(it.className, cx(ClassName("input_text"), if (it.isSearch == true) ClassName("input_text_search") else null))
    }
}