package components.parts

import csstype.PropertiesBuilder
import efas.common.EfasColor
import emotion.react.css
import react.FC
import react.dom.html.ButtonHTMLAttributes
import react.dom.html.ReactHTML.button
import style.SvgIcon
import style.icon
import web.cssom.*
import web.cssom.None.Companion.none
import web.html.HTMLButtonElement

external interface ButtonProps : ButtonHTMLAttributes<HTMLButtonElement> {
    var color: EfasColor?
    var text: String?
    var icon: SvgIcon?
    var faIcon: String?
    var outline: Boolean?
    var dark: Boolean?
    var selected: Boolean?
}

object Button {
    internal fun PropertiesBuilder.solidColor(it: EfasColor?, selected: Boolean = false) {
        val color = it ?: EfasColor.BLUE
        backgroundColor = if (selected) Color(color.shadow) else Color(color.primary)
        active {
            backgroundColor = Color(color.shadow)
        }
        disabled {
            backgroundColor = Color(color.highlight)
        }
    }

    private fun PropertiesBuilder.outline(it: EfasColor?) {
        val color = it ?: EfasColor.BLUE
        backgroundColor = Color(EfasColor.WHITE)
        outlineStyle = LineStyle.solid
        outlineWidth = 2.px
        outlineColor = Color(color.primary)
        this.color = Color(color.primary)

        active {
            outlineColor = Color(color.shadow)
            this.color = Color(color.shadow)
        }
        disabled {
            outlineColor = Color(color.highlight)
            this.color = Color(color.highlight)
        }
    }

    private fun ButtonHTMLAttributes<HTMLButtonElement>.bindContent(props: ButtonProps) {
        props.icon?.let {
            it.component {
                fill = if (props.outline == true) props.color?.shadow ?: EfasColor.BLUE.shadow else EfasColor.WHITE
            }
        }
        props.faIcon?.let { icon(it) }
        props.text?.let { +it }
        props.onClick?.let { onClick = it }
    }

    val Large = FC<ButtonProps> { props ->
        button {
            css(ClassName("button_large"), props.className) {
                props.outline
                    ?.let { _ -> outline(props.color) }
                    ?: solidColor(props.color)
            }
            bindContent(props)
        }
    }

    val Small = FC<ButtonProps> {props ->
        button {
            css(ClassName("button_small"), props.className) {
                props.outline
                    ?.let { _ -> outline(props.color) }
                    ?: solidColor(props.color, props.selected ?: false)
            }
            bindContent(props)
        }
    }

    val Wide = FC<ButtonProps> {props ->
        button {
            css(ClassName("button_wide"), props.className) {
                props.outline
                    ?.let { _ -> outline(props.color) }
                    ?: solidColor(props.color)
            }
            bindContent(props)
        }
    }

    val Circle = FC<ButtonProps> { props ->
        button {
            css(ClassName("button_circle"), props.className) {
                if (props.disabled == true) {
                    color = Color(EfasColor.GREY.shadow)
                    backgroundColor = Color(EfasColor.GREY.highlight)
                } else {
                    props.outline
                        ?.let { _ -> outline(props.color) }
                        ?: solidColor(props.color)
                }
                fontSize = 2.6.rem
            }
            bindContent(props)
        }
    }

    /**
     * Special case; mostly used for activity footer
     */
    val CircleFlat = FC<ButtonProps> { props ->
        val color = props.color ?: EfasColor.GREY
        button {
            +props
            css(ClassName("button_circle"),
                when {
                    props.selected == true ->
                        ClassName("inset_shadow")
                    disabled != true ->
                        ClassName("inset_active")
                    else -> null
                }
            ) {
                if (props.disabled == true) {
                    this.color = Color(EfasColor.GREY.shadow)
                    backgroundColor = Color(EfasColor.GREY.primary)
                } else {
                    this.color = Color(color.shadow)
                    backgroundColor = Color(EfasColor.GREY.highlight)
                }
                if (props.selected != true) boxShadow = none
            }
            props.icon?.let {
                it.component {
                    fill = if(props.disabled == true) EfasColor.GREY.shadow else color.shadow
                }
            }
            props.faIcon?.let { icon(it) }
            props.text?.let { +it }
            props.onClick?.let { onClick = it }
        }
    }

    val Square = FC<ButtonProps> { props ->
        button {
            css(ClassName("button_square"), props.className) {
                props.outline
                    ?.let { _ -> outline(props.color) }
                    ?: solidColor(props.color)
            }
            bindContent(props)
        }
    }

    val Menu = FC<ButtonProps> {props ->
        val propcolor = props.color ?: EfasColor.BLUE
        button {
            css(ClassName("button_menu"), props.className) {
                backgroundColor = Color(propcolor.primary)
                color = Color(propcolor.shadow)
            }
            props.icon?.let {
                it.component {
                    fill = props.color?.shadow ?: EfasColor.BLUE.shadow
                }
            }
            props.faIcon?.let { icon(it) }
            props.text?.let { +it.uppercase() }
            props.onClick?.let { onClick = it }
        }
    }
}