package efas.common.api

import efas.common.objects.*
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.polymorphic
import kotlin.reflect.KClass

/**
 * Explicit definitions to help Kotlinx Serialization
 * deal with polymorphism in unsealed/non-enum classes
 */

val contextModule = SerializersModule {

    polymorphic(Message::class) {
        subclass(Ok::class, Ok.serializer())
        subclass(Content::class, Content.serializer(PolymorphicSerializer(Send::class)))
        subclass(Error::class, Error.serializer())
    }
//    define sendable api.objects
    polymorphic(Send::class) {
        subclass(User::class, User.serializer())
        subclass(Progress::class, Progress.serializer())
        subclass(MetaData::class, MetaData.serializer())
        subclass(Song::class, Song.serializer())
        subclass(Test::class, Test.serializer())
        subclass(Inventory::class, Inventory.serializer())
        subclass(Result::class, Result.serializer())
        subclass(Game::class, Game.serializer())
        subclass(Rubric::class, Rubric.serializer())
        subclass(Token::class, Token.serializer())
    }

    polymorphic(Answerable::class) {
        subclass(Answer::class, Answer.serializer())
        subclass(SortAnswer::class, SortAnswer.serializer())
        subclass(ResultAnswer::class, ResultAnswer.serializer())
        subclass(SortCategory::class, SortCategory.serializer())
    }
}

enum class MarkerTypes(val type: KClass<out Send>) {
    User(efas.common.objects.User::class),
    Progress(efas.common.objects.Progress::class),
    Test(efas.common.objects.Test::class),
    Song(efas.common.objects.Song::class),
    TestResult(efas.common.objects.Result::class),
    Inventory(efas.common.objects.Inventory::class),
    Game(efas.common.objects.Game::class),
}