package efas.common.objects

import efas.common.UUID
import efas.common.api.Client
import efas.common.api.Error
import efas.common.api.Message
import efas.common.api.PasswordAsBase64
import efas.common.baseURL
import io.ktor.client.call.*
import io.ktor.client.request.*
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("User")
data class User (override val name: String = "",
                 val email: String = "",
                 val password: PasswordAsBase64 = Password.NIL,
                 override val id: UUID = UUID.NIL,
                 override val modified: Instant = Clock.System.now(),
                 override val isActive: Boolean = true
) : Public {
    fun setPassword(raw: String) : User = this.copy(password = Password.fromRawString(raw, id))
    companion object {
        inline operator fun <T : Any?> invoke(op: API.() -> T) : T = API.op()
    }
    object API: Client("$baseURL/users") {
        suspend fun get(): Message =
            try { client.get(path).body() }
            catch (e: Throwable) { Error(clientMessage + e.message) }
    }
}