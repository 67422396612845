package efas.common.api

import efas.common.objects.Send
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Message: API Algebraic Container Type
 *  Variants:
 *      - OK
 *      - Error
 *      - Content
 *      - Delta (Update)
 */
@Serializable
sealed interface Message {

    fun <T: Send> bind(success: (T)->Unit, failure: (String)->Unit) = this
        .content(success)
        .error(failure)

    infix fun <T: Send> content(op: (T)->Unit) : Message =
        if (this is Content<*>) {
            op(this.obj as T)
            this
        }
        else this

    suspend infix fun <T: Send> then(op: suspend (T)->Message) : Message =
        if (this is Content<*>) op(this.obj as T)
        else this

    infix fun<R> ok(op: ()->R) : Message =
        if (this is Ok)
                (op() as? Message) ?: this
        else this

    infix fun<R> error(op: (String)->R) : Message =
        if (this is Error)
            (op(message ?: "") as? Message) ?: this
        else this

}

@Serializable
@SerialName("Ok")
data object Ok : Message

@Serializable
@SerialName("Content")
data class Content<out T : Send> (val obj: T) : Message

@Serializable
@SerialName("Error")
data class Error (val message: String? = null) : Message

