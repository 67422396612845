package components.activity

import components.parts.Button
import components.parts.Button.solidColor
import efas.common.Category
import efas.common.objects.Answer
import efas.common.objects.Sort
import efas.common.objects.SortAnswer
import emotion.css.cx
import emotion.react.css
import kotlinx.browser.window
import react.*
import react.beautiful.dnd.DragDropContext
import react.beautiful.dnd.Draggable
import react.beautiful.dnd.Droppable
import react.dom.aria.AriaRole
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.header
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import style.Layout
import web.cssom.*
import web.html.HTMLDivElement
import web.html.HTMLElement


val BucketSort = FC<ActProps> { props ->
    props.obj as Sort

    var cardIndex by useState(0)
    var currentCard by useState<SortAnswer>()

    var answers by useState<Map<String, List<String>>>(mapOf())

    useEffect(props.obj) { cardIndex = 0 }

    useEffect(cardIndex) {
        if (cardIndex >= props.obj.unsafeCast<Sort>().possibilities.size) props.onNext(Answer())
        else currentCard = props.obj.unsafeCast<Sort>().possibilities[cardIndex]
    }

    DragDropContext {
        onDragEnd = { result, _ ->
            if (result.destination!= null && result.destination?.droppableId != "source") {
                var new = false

                val entries = answers[result.destination?.droppableId]?.plus(result.draggableId)
                    ?: listOf(result.draggableId).also { new = true }

                val lp: List<Pair<String, List<String>>> =
                    if (new) answers.entries.map { it.key to it.value } +
                            (result.destination!!.droppableId to entries)
                    else answers.entries.toList().map {
                        if (it.key == result.destination?.droppableId) it.key to entries
                        else it.key to it.value
                    }

                answers = lp.toMap()
                cardIndex++
            }
        }

        div {
            className = cx(Layout.columnEven, ClassName("layout_fill"), Layout.defaultPadding)
            header {
                css { color = Color(props.color.shadow) }
                +"BUCKET SORT"
            }

            div {
                className = Layout.rowEven

                (props.obj as Sort).categories.forEach { cat ->
                    Droppable {
                        droppableId = cat.category
                        children = { provided, state ->
                            div.create {
                                ref = provided.innerRef
                                +provided.droppableProps
                                css(Layout.column, ClassName("font_center")) {
                                    if(state.isDraggingOver) color = Color(props.color.primary)
                                    else color = Color(props.color.shadow)
                                }
                                h1 { +cat.category }
                                img {
                                    src = "images/sortBucket.svg"
                                    height = 120.0
                                }
                                answers.get(cat.category)?.forEach {
                                    p { +it }
                                }
                            }
                        }
                    }
                }
            }

            Droppable {
                droppableId = "source"
                children = { provided, _ ->
                    div.create {
                        ref = provided.innerRef
                        +provided.droppableProps
                        currentCard?.let {
                            Draggable {
                                draggableId = it.answer
                                index = cardIndex
                                children = { provided, state, rubric ->
                                    div.create {
                                        div {
                                            ref = provided.innerRef
                                            +provided.dragHandleProps
                                            +provided.draggableProps

                                            css(ClassName("sort_card"), Layout.defaultPadding) {
                                                solidColor(props.color)
                                                if (state.isDragging) {
                                                    opacity = number(0.8)
                                                }
                                            }
                                            h1 { +(currentCard?.answer ?: "") }
                                        }
                                        if (state.isDragging)
                                            div {
                                                css(ClassName("placeholder_card")) {
                                                    borderColor = Color(props.color.highlight)
                                                }
                                            }
                                    }
                                }
                            }
                        }
                            ?: div {
                                css(ClassName("placeholder_card")) {
                                    borderColor = Color(props.color.highlight)
                                }
                            }
                    }
                }
            }
        }
    }
}

/*val SortA = fc<ActProps> { props ->
    props.obj as Sort
    var cardIndex by useState(0)
    var currentCard by useState<SortAnswer>()

    useEffect(props.obj) { cardIndex = 0 }

    useEffect(cardIndex) {
        if (cardIndex >= props.obj.unsafeCast<Sort>().possibilities.size) props.resultCallback(
            ResultAnswer(results.map {
                val isCorrect = it.key.category == it.value
                Answer(it.key.answer,
                    if(isCorrect) it.key.category.trueFeedback else it.key.category.falseFeedback,
                    isCorrect
                )
            })
        )
        else
        currentCard = props.obj.unsafeCast<Sort>().possibilities[cardIndex]
    }

    val (category, bindSource, bindDestination) = useDragAndDrop { actual ->
        val goNext = currentCard?.let { expected ->
            if (expected.category.category == actual.category.category)
                Answer("${actual.answer} -> ${actual.category.category}", expected.category.trueFeedback, true)
            else Answer("${actual.answer} -> ${actual.category.category}",expected.category.falseFeedback, false)
        }?.let(props.submitResult) ?: false

        if (goNext) {
            if (cardIndex < props.obj.unsafeCast<Sort>().possibilities.size - 1) cardIndex++
            else props.next()
        }
    }


    styledDiv {
        css { +Layout.flexEven }
        (props.obj as Sort).categories.forEach { destination ->
            styledH2 {
                attrs.key = destination.hashCode().toString()
                css {
                    +card
                    borderStyle = BorderStyle.dashed
                    borderColor = Color(EfasColor.GREY.primary)
                    color = Color(EfasColor.GREY.shadow)
                    hover {
                        backgroundColor = Color(EfasColor.GREY.highlight)
                    }
                }
                +destination.category
                bindDestination()
            }
        }
        styledH2 {
            css {
                +card
                backgroundColor = Color(EfasColor.GREEN.primary)
                color = Color(EfasColor.GREEN.shadow)
            }
            +(currentCard?.answer ?: "")
            bindSource()
        }
    }
}*/
