package components.activity

import Application.store
import Application.user
import components.parts.Button
import efas.common.Category
import efas.common.EfasColor
import efas.common.objects.ContentType
import emotion.react.css
import hooks.useStore
import prototype.ApiAction
import react.FC
import react.PropsWithClassName
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.optgroup
import react.dom.html.ReactHTML.option
import react.dom.html.ReactHTML.select
import react.router.useNavigate
import react.useEffectOnce
import react.useState
import style.Layout
import web.cssom.ClassName
import web.cssom.Color
import web.cssom.rem

val SkillChooser = FC<PropsWithClassName> {
    var category by useState<Category?>(null)
    var level by useState<Int?>(null)
    val user = useStore(user)
    val state = useStore(store)
    val nav = useNavigate()

    // Update inventory/metadata
    useEffectOnce {
        store.dispatch(ApiAction.Refresh(ContentType.GAME))
        store.dispatch(ApiAction.Refresh(ContentType.SONG))
    }

    div {
        css(Layout.columnEven, ClassName("layout_fill"), Layout.defaultPadding) {
            backgroundColor = Color(user.colorScheme.highlight)
            ReactHTML.label {
                fontSize = 2.6.rem
                marginBottom = 1.rem
            }
        }

        div {
            className = Layout.columnCenter
            label {
                htmlFor = "skill"
                +"CHOOSE A SKILL"
            }

            select {
                name = "skill"
                Category.SkillGroup.values().forEach { group ->
                    optgroup {
                        label = group.name

                        option {
                            +"Any"
                        }

                        Category.values()
                            .filter { it.group == group }
                            .forEach {
                                option {
                                    +it.name.replace("_", " ")
                                        .lowercase()
                                        .capitalize()
                                }
                            }
                    }
                }

                onChange = {
                    category = if (it.currentTarget.value == "Any") null
                    else Category.valueOf(
                        it.currentTarget.value
                            .replace( " ", "_")
                            .uppercase()
                    )
                }
            }
        }


        div {
            className = Layout.columnCenter
            ReactHTML.label {
                htmlFor = "level"
                +"CHOOSE A LEVEL"
            }

            select {
                name = "label"
                repeat(4) {
                    ReactHTML.option {
                        +if(it == 0) "Any" else it.toString()
                    }
                }

                onChange = {
                    level = if (it.currentTarget.value == "Any") null
                    else it.currentTarget.value.toIntOrNull()
                }
            }
        }

        Button.Wide {
            color = EfasColor.GREEN
            text = "Play"
            onClick = {
                val game = state.game.inventory.filter { it.isActive }
                    .let {
                        when {
                            category != null && level != null -> it
                                .filter { it.rubric?.categories?.contains(category) ?: false }
                                .filter { it.rubric?.level == level }
                            category != null -> it
                                .filter { it.rubric?.categories?.contains(category) ?: false }
                            level != null -> it
                                .filter { it.rubric?.level == level }
                            else -> it
                        }
                    }.randomOrNull()
                    ?: state.game.inventory.filter { it.isActive }.randomOrNull()
                        .also { console.warn("No game found matching skill/level request; resorting to random") }

                game?.let { nav("/play?game=${it.id}") }
                    ?: run {
                        console.error("No content available! Returning to main menu")
                        nav("/")
                    }
            }
        }
    }
}