package efas.common.objects

import efas.common.Category
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Determines how results of user acts are applied to Progress
 *
 * @property level
 * @property categories
 */

@Serializable
@SerialName("Rubric")
data class Rubric (
    val level: Int = 0,
    val categories: List<Category> = listOf()) : Send