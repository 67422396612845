package efas.common.objects

import efas.common.UUID
import efas.common.api.Client
import efas.common.api.InstantAsLongSerializer
import efas.common.baseURL
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("Test")
data class Test(override val id: UUID = UUID.NIL,
                override val name: String? = null,
                val questions: List<Activity> = listOf(),
                override val isActive: Boolean = false,
                val rubric: Rubric? = null,
                val prompt: String? = null,
                @Serializable(with = InstantAsLongSerializer::class)
                override val modified: Instant = Clock.System.now()) : Content {

    companion object {
        inline operator fun <T : Any?> invoke(op: API.() -> T) : T = API.op()
    }

    object API : Client("$baseURL/tests")
}