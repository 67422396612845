
import components.activity.SkillChooser
import components.activity.TestComponent
import components.infrastructure.HelpComponent
import components.infrastructure.SettingsComponent
import components.progress.ProgressComponent
import components.song.SelectSong
import react.FC
import style.*

const val splashTimeout = 2000L

enum class MenuOption(val text: String, val icon: SvgIcon, val path: String, val component: FC<*>) {
    Play("Play By Skill", bucket, "/game", SkillChooser),
    Song("Play By Song", eighthNote, "/song", SelectSong),
    Progress( "Progress", listCheck, "/progress", ProgressComponent),
    Test("Testing", page, "/test", TestComponent),
    Settings( "Settings", gears, "/settings", SettingsComponent),
    Help("Help", question, "/help", HelpComponent),
}

