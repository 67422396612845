package efas.common.api

import efas.common.UUID
import efas.common.api.PlatformHash.fromBase64
import efas.common.api.PlatformHash.toBase64
import efas.common.objects.Activity
import efas.common.objects.Password
import efas.common.objects.Send
import kotlinx.datetime.Instant
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.Serializer
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.*
import kotlin.reflect.KClass
import kotlin.time.Duration
import kotlin.time.DurationUnit
import kotlin.time.toDuration


/**
 * Custom Serializer for sending [UUID] (encoded as base64 string)
 */

object UUIDSerializer : KSerializer<UUID> {
    override val descriptor: SerialDescriptor =
        PrimitiveSerialDescriptor("UUID", PrimitiveKind.STRING)
    override fun serialize(encoder: Encoder, value: UUID) {
        encoder.encodeString(value.getBytes().toBase64())
    }

    override fun deserialize(decoder: Decoder): UUID {
        return UUID(decoder.decodeString().fromBase64())
    }
}


/**
 * Custom Serializer for sending [Instant] as Long
 */
typealias InstantAsLong = @Serializable(with = InstantAsLongSerializer::class) Instant
object InstantAsLongSerializer : KSerializer<Instant> {
    override val descriptor: SerialDescriptor =
        PrimitiveSerialDescriptor("millis", PrimitiveKind.LONG)
    override fun serialize(encoder: Encoder, value: Instant) {
        encoder.encodeLong(value.toEpochMilliseconds())
    }

    override fun deserialize(decoder: Decoder): Instant {
        return Instant.fromEpochMilliseconds(decoder.decodeLong())
    }
}

/**
 * Custom Serializer for sending [Duration] as Long
 */
typealias DurationAsLong = @Serializable(with = DurationAsLongSerializer::class) Duration

object DurationAsLongSerializer : KSerializer<Duration> {
    override val descriptor: SerialDescriptor =
        PrimitiveSerialDescriptor("durationAsLong", PrimitiveKind.LONG)
    override fun serialize(encoder: Encoder, value: Duration) {
        encoder.encodeLong(value.inWholeMilliseconds)
    }

    override fun deserialize(decoder: Decoder): Duration {
        return decoder.decodeLong().toDuration(DurationUnit.MILLISECONDS)
    }
}

/**
 * Custom Serializer for sending [Password]
 */

typealias PasswordAsBase64 = @Serializable(with = PasswordSerializer::class) Password

object PasswordSerializer : KSerializer<Password> {
    override val descriptor: SerialDescriptor =
        PrimitiveSerialDescriptor("password", PrimitiveKind.STRING)
    override fun serialize(encoder: Encoder, value: Password) {
        encoder.encodeString(value.value.toBase64())
    }

    override fun deserialize(decoder: Decoder): Password {
        return Password(decoder.decodeString().fromBase64())
    }
}


/**
 * Custom Serializer for sending Type Arguments encoded as a string
 *
 * acceptedTypes list must be modified if new types are added
 */

typealias TypeAsString = @Serializable(with = TypeSerializer::class) KClass<out Send>

@Serializer(forClass = KClass::class)
object TypeSerializer : KSerializer<KClass<out Send>> {
    override val descriptor: SerialDescriptor =
        PrimitiveSerialDescriptor("Data Type", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: KClass<out Send>) {
//        Error(ApiError.SerializationError, "unable to determine qualified name for class")
        encoder.encodeString(value.simpleName ?: throw error("unable to determine qualified name for class"))
    }

    override fun deserialize(decoder: Decoder): KClass<out Send> {
        return reconstitute(decoder.decodeString())
    }

    private fun reconstitute(type: String) : KClass<out Send> = MarkerTypes.valueOf(type).type
}

fun Any.toJsonElement() : JsonElement = when (this) {
    is Activity -> Client.json.encodeToJsonElement(this)
    is Send -> Client.json.encodeToJsonElement(this)
    is Map<*, *> -> {
        val mapContents = entries.associate { mapEntry ->
            mapEntry.key.toString() to mapEntry.value!!.toJsonElement()
        }
        JsonObject(mapContents)
    }
    is List<*> -> {
        val arrayContents = map { listEntry -> listEntry!!.toJsonElement() }
        JsonArray(arrayContents)
    }
    is Number -> JsonPrimitive(this)
    is Boolean -> JsonPrimitive(this)
    else -> JsonPrimitive(this.toString())
}

