package efas.common.api

import efas.common.UUID
import efas.common.objects.Send
import efas.common.objects.User
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.request.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.serialization.json.Json


/**
 * EFAS API CLIENT
 *
 * Contains api client object as well as basic request templates
 *
 * Each domain object implements this as an internal object,
 * giving them a default set of operations for their endpoint path.
 *
 * Api operations can then be overridden or added as needed for each object
 *
 * @see [efas.common.objects.Song]
 *
 * Warning: If a companion object implements this class, it'll interfere with Kotlinx Serialization
 */


abstract class Client(val path: String) {

    //    api prototype functions
    open suspend fun get(id: UUID): Message =
        try { client.get("${path}/$id").body() }
        catch (e: Throwable) { Error(clientMessage + e.message) }

    open suspend fun inventory(id: UUID = UUID.NIL): Message =
        try { client.get(path).body() }
        catch (e: Throwable) { e.printStackTrace(); Error(clientMessage + e.message) }

    open suspend fun post(obj: Send): Message =
        try {
            client.post(path) {
                contentType(ContentType.Application.Json)
                setBody<Message>(Content(obj))
            }.body()
        } catch (e: Throwable) { Error(clientMessage + e.message) }

    open suspend fun put(obj: Send): Message =
        try {
            client.put(path) {
                contentType(ContentType.Application.Json)
                setBody<Message>(Content(obj))
            }.body()
        } catch (e: Throwable) { Error(clientMessage + e.message) }

    open suspend fun setEnabled(id: UUID, active: Boolean): Message =
        try {
            client.patch("$path/$id?active=$active") {
                contentType(ContentType.Application.Json)
            }.body()
        } catch (e: Throwable) { Error(clientMessage + e.message) }

    open suspend fun delete(id: UUID): Message =
        try { client.delete("${path}/$id").body() }
        catch (e: Throwable) { Error(clientMessage + e.message) }

    companion object {
        var currentUser : User? = null
        val json = Json {
            serializersModule = contextModule
            prettyPrint = true
            ignoreUnknownKeys = true
            isLenient = true
        }
        var client : HttpClient = HttpClient() {
            install(ContentNegotiation) { json(json) }
            defaultRequest {
                Token.fromLocal()?.let(::header)
            }
            expectSuccess = false
        }.configureAuthInterceptor()
        const val clientMessage = "Client error: "
    }
}

/**
 * Jwt bearer implementation for the client;
 *      Upon auth failure 401, will try to repeat the request after properly authenticating with `Token.fromIssuer()`
 */
fun HttpClient.configureAuthInterceptor() = this.apply {
    this.plugin(HttpSend).intercept { request ->
        // do initial request
        val intercepted = execute(request)
        if (intercepted.response.status == HttpStatusCode.Unauthorized) {
//            if invalid session, get a fresh token from the auth endpoint
//              then try the original request again
            Token.fromIssuer()?.let {
                request.apply { header(it) }
                execute(request)
            } ?: intercepted
        } else intercepted
    }
}
