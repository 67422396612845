package components.activity

import Application.store
import Application.user
import components.Error
import components.SlideDeck
import components.parts.Footer
import components.parts.ViewState
import components.parts.wrapLoading
import components.song.Listen
import efas.common.UUID
import efas.common.objects.ContentType
import hooks.useStore
import prototype.ApiAction
import react.*
import react.dom.html.ReactHTML.header
import react.router.dom.useSearchParams
import react.router.useNavigate
import web.timers.setTimeout
import kotlin.time.Duration.Companion.seconds

val Play = FC<Props> {
    val state = useStore(store)
    val user = useStore(user)
    val (query, _) = useSearchParams()
    var isStart by useState(true)
    var viewState by useState(ViewState.LISTEN)
    var isFailure by useState(false)
    val sequenceIndexState = useState(0)
    val nav = useNavigate()

    val fail = {
        isFailure = true
        console.error("[Play] Couldn't find item to download")
        setTimeout(5.seconds) { nav("/") }
    }

    /**
     * Logic for downloading Song/Game sequence
     */
    useEffect(query) {
        if (query.get("skip")?.toBoolean() == true) {
            isStart = false
            viewState = ViewState.PLAY
        }
        val gameId = query.get("game")
        val songId = query.get("song")

        when {
            gameId != null -> state.game.inventory
                .filter { it.isActive }
                .find { it.id == UUID.fromString(gameId) }
                ?.let { game ->
                    game.song
                        ?.let { songId ->   // try songId from game inventory
                            store.dispatch(ApiAction.Download(songId, ContentType.SONG))
                        }
                        ?: songId           // try songId from params
                            ?.takeIf { id -> state.song.inventory.any { it.id == UUID.fromString(id) } }
                            ?.let {
                                store.dispatch(ApiAction.Download(UUID.fromString(it), ContentType.SONG))
                            }
                        ?: fail()


                    store.dispatch(ApiAction.Download(UUID.fromString(gameId), ContentType.GAME))
                }

            songId != null -> state.game.inventory // if only songId present, find a matching game
                .filter { it.isActive }
                .find { it.song == UUID.fromString(songId) }
                ?.let {
                    store.dispatch(ApiAction.Download(UUID.fromString(songId), ContentType.SONG))
                    store.dispatch(ApiAction.Download(it.id, ContentType.GAME))
                }
                ?: fail()

            else -> fail()
        }
    }

    /**
     * Component Layout
     */

    if (!isFailure) {
        when(viewState) {
            ViewState.LISTEN ->
                wrapLoading(state.loading, state.song.item) {
                    Listen {
                        song = it
                        color = user.colorScheme
                        onEnd = {
                            isStart = false
                            viewState = ViewState.PLAY
                        }
                        showHeader = isStart
                    }
                }

            ViewState.PLAY ->
                wrapLoading(state.loading, state.game.item) {
                    ActivitySequence {
                        index = sequenceIndexState
                        checkAnswer = { _,_ -> true }
                        onDone = { nav("/") }
                        color = user.colorScheme
                        activities = it.activities
                    }
                }

            ViewState.INSTRUCTION ->
                wrapLoading(state.loading, state.game.item) {
                    SlideDeck {
                        color = user.colorScheme
                        content = it.activities.getOrNull(sequenceIndexState.component1())?.let {
                            mapOf("" to it.instruction)
                        } ?: mapOf()
                        skills = it.activities.getOrNull(sequenceIndexState.component1())?.rubric?.categories ?: listOf()
                    }
                }

            ViewState.HELP -> GameHelp {
                color = user.colorScheme
                onBack = { viewState = ViewState.PLAY }
            }
        }
    } else Error { message = "Unable to download selected song or game" }

    if (!isStart && viewState != ViewState.HELP) Footer {
        color = user.colorScheme
        this.viewState = viewState
        onListen = {
            viewState = if (viewState != ViewState.LISTEN) ViewState.LISTEN else ViewState.PLAY
        }
        onInstruction = {
            viewState = if (viewState != ViewState.INSTRUCTION) ViewState.INSTRUCTION else ViewState.PLAY
        }
        onHelp = {
            viewState = if (viewState != ViewState.HELP) ViewState.HELP else ViewState.PLAY
        }
    }
}



//val GameComponentA = fc<Props> {
//    val state = useStore(store)
//    val nav = useNavigate()
//
//    var feedback by useState<String>()
//    var correct by useState<Boolean>()
//    var currentAct by useState<Activity>()
//
//    var listen by useState<Boolean>(true)
//
//    // Download content
//    useEffect(state.game.inventory) {
//        (state.game.inventory.filter { (it as Public).isActive }.randomOrNull() as? Game)?.let {
//            scope.asyncWithLoading(store) {
//                var songId: UUID? = null
//
//                Game { get(it.id) }.content<Game> {
//                    store.dispatch(LocalAction.Import(it))
//                    songId = it.song
//                } error(::error)
//
//                songId?.let {
//                    Song { get(it) }.content<Song> { store.dispatch(LocalAction.Import(it)) } error(::error)
//                } ?: run { listen = false; println("Warning! No song found for game") }
//            }
//        }
//    }
//
//    useEffect(state.game.item) {
//        store.state.game.item?.song?.let { current ->
//            (store.state.song.inventory as List<Song>).firstOrNull { it.id == current }
//        }
//    }

//    val feedbackModal = useDialog(ColorScheme.Light) {
//        FeedbackModal {
//            attrs.message = feedback ?: ""
//            correct?.let { attrs.correct = it }
//        }
//    }
//
//    val instructionModal = useDialog(ColorScheme.Light) {
//        InstructionModal {
//            attrs {
//                prompt = (currentAct?.content ?: "")
//                this.skills = currentAct?.rubric?.categories
//                    ?: state.game.item?.rubric?.categories
//                    ?: listOf()
//            }
//        }
//    }
//
//    val (audio, clock) = useAudio(state.song.item)
//
//    // do the game after the listening screen
//    audio.current?.onended = { listen = false; Unit }
//
//    if (listen) state.song.item?.let {
//        h2 { +"Listening Challenge" }
//        styledDiv {
//            css {
//                +ComponentStyle.lyricView
//                maxHeight = 100.pct
//                padding = Padding(2.rem)
//                fontSize = 8.rem
//            }
//            LyricSeqComponent {
//                attrs {
//                    lyrics = it.lyrics
//                    this.clock = clock.component1()
//                }
//            }
//            br {}
//        }
//    }  ?: Spinner(EfasColor.BLACK)
//
//    else state.game.item?.let {
//        ActSequence {
//            attrs {
//                obj = it
//                validate = { act, ans ->
//                    feedback = ans.feedback
//                    correct = ans.correct
//                    feedbackModal.current?.showModal()
//                    (scope + timeoutSupervisor).launch {
//                        delay(3000)
//                        feedbackModal.current?.close()
//                    }
//                    ans.correct
//                }
//                done = {
//                    feedback = "${it.count { (it as Answer).correct }} correct out of ${it.count()}"
//                    correct = true
//                    feedbackModal.current?.showModal()
////                    nav("/menu")
//                }
//                linkCurrent = {
//                    currentAct = it
//                    if (it !is MultipleChoice) instructionModal.current?.showModal()
//                }
//            }
//        }
//    } ?: Spinner(EfasColor.BLACK)
//
//    PlaybackNav {
//        attrs {
//            instructionScreen = instructionModal
//            this.audio = audio
//            onForward = {
//                audio.current?.apply {
//                    currentTime += 15.0
//                }
//            }
//            onReverse = {
//                audio.current?.apply {
//                    currentTime -= 15.0
//                }
//            }
//        }
//    }

//}