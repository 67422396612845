package components.infrastructure

import components.SlideDeck
import efas.common.EfasColor
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import style.Layout

val HelpComponent = FC<Props> {
    div {
        className = Layout.innerFull

        ReactHTML.header { +"HELP" }

        SlideDeck {
            color = EfasColor.BLUE
            content = mapOf(
                "Page One" to "This is the time and this is the record of the time",
                "Page Two" to "This is the time and this is the record of the time",
                "Page Three" to "This is the time and this is the record of the time",
                "Page Four" to "This is the time and this is the record of the time"
            )
        }
    }
}