package components.onboarding

import Application.store
import Application.user
import components.parts.Button
import efas.common.EfasColor
import efas.common.UUID
import efas.common.objects.Password
import efas.common.objects.User
import emotion.css.cx
import emotion.react.css
import hooks.useStore
import prototype.LocalAction
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.hr
import react.dom.html.ReactHTML.img
import react.router.useNavigate
import react.useEffectOnce
import react.useState
import style.Layout
import web.cssom.*
import web.timers.clearTimeout
import web.timers.setTimeout
import kotlin.time.Duration.Companion.milliseconds


val IntroComponent = FC<Props> {
    var isSplash by useState(true)
    val user = useStore(user)
    val nav = useNavigate()

    useEffectOnce {
        val timeoutId = setTimeout(store.state.displayTimeout.milliseconds) { isSplash = false }
        cleanup { clearTimeout(timeoutId) }
    }

    val dummySignin = {
        Application.user.dispatch(LocalAction.Replace(User("Demo User", "email@email.com", Password.fromRawString("testpassword", UUID.randomUUID()), UUID.randomUUID())))
    }

    if (isSplash) {
        div {
            className = cx(Layout.innerEven, ClassName("intro_logo"), ClassName("jump"))
            img {
                alt = "English for a Song Logo"
                src = "images/EnglishForASong.png"
            }
        }
    } else {
        div {
            css(Layout.innerEven, ClassName("font_center"), ClassName("intro_logo")) {
                padding = Padding(10.vh, 2.rem)
                backgroundColor = Color(EfasColor.BLUE.highlight)
                color = Color(EfasColor.BLACK)
            }
            img {
                alt = "English for a Song Logo"
                src = "images/EnglishForASong.png"
            }
            hr {
                css(ClassName("divider")) {
                    borderColor = Color(EfasColor.BLACK)
                }
            }
            h1 {
                +"Tune in to great hit songs - and fine-tune your English skills!"
            }
            Button.Large {
                text = "Sign In"
                color = EfasColor.BLUE
                onClick = {
                    dummySignin()
                    nav("/")
                }
            }
            Button.Large {
                text = "Register"
                color = EfasColor.BLUE
                outline = true
                onClick = {
                    dummySignin()
                    nav("/")
                }
            }
        }
    }
}