package efas.common.objects

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.reflect.KClass

/**
 * ACT activity type can be manifested as either a [Game] or a [TestAct];
 * is used as data object for handling test questions or games and
 * their different possible types
 *
 * Everything in this file lives in the DB in a serialized form, therefore,
 * it's important that the serial name remain consistent
 *
 */

@Serializable
sealed class Activity : Send {
    abstract val rubric: Rubric
    abstract val content: String
    abstract val possibilities: List<Answerable>
    abstract val instruction: String

    inline fun<reified R: Activity> into(marker: KClass<out R>): R = when (marker) {
        MultipleChoice::class -> MultipleChoice(rubric,content, instruction = instruction) as R
        SelectAll::class -> SelectAll(rubric,content, instruction = instruction) as R
        Sort::class -> Sort(rubric,content, instruction = instruction) as R
        else -> error("wrong")
    }

    fun copy(rubric: Rubric = this.rubric,
             content: String = this.content,
             instruction: String = this.instruction,
             possibilities: List<Answerable> = this.possibilities,
             example: String? = (this as? SelectAll)?.example) : Activity =
        when (this) {
            is MultipleChoice -> copy(rubric, content, possibilities as List<Answer>, instruction)
            is SelectAll -> copy(rubric, content, possibilities as List<Answer>, instruction, example ?: "")
            is Sort -> copy(rubric, content, possibilities as List<SortAnswer>, instruction)
        }

    enum class Lookup(val marker: KClass<out Activity>) {
        MultipleChoice(efas.common.objects.MultipleChoice::class),
        SelectAll(efas.common.objects.SelectAll::class),
        Sort(efas.common.objects.Sort::class),
    }
}

@Serializable
@SerialName("MultipleChoice")
data class MultipleChoice (override val rubric: Rubric = Rubric(),
                           override val content: String = "",
                           override val possibilities: List<Answer> = listOf(),
                           override val instruction: String = ""
) : Activity(), TestAct, GameAct

@Serializable
@SerialName("SelectAll")
data class SelectAll (override val rubric: Rubric = Rubric(),
                      override val content: String = "",
                      override val possibilities: List<Answer> = listOf(),
                      override val instruction: String = "",
                      val example: String = "") : Activity(), TestAct, GameAct {

    /**
     * Combine a SelectAnswer with the original to either get the first wrong word,
     *      or null if all choices are correct
     */
    fun merge(ans: ResultAnswer) : Answer {
        val raw = ans.choices.map { it.answer }.joinToString(", ")

        val chosen = ans.choices.filter { it.correct }
        val total = this.possibilities.filter { it.correct }

        ans.choices.firstOrNull { !it.correct }?.let {
            return Answer(raw, it.feedback, false)
        }

        return when {
            total.isEmpty() && raw.isEmpty() ->
                Answer(raw,  total.firstOrNull()?.feedback ?: "Correct! None of the above", true)
            chosen.size != total.size ->
                Answer(raw, "Answer is partly correct, but incomplete", false)
            else -> {
                val correct = total.map { it.answer.lowercase() }
                val current = chosen.map { it.answer.lowercase() }
                return if (current.any { !correct.contains(it) }) Answer(raw, "", false)
                else Answer(raw, total.first().feedback, true)
            }
        }
    }

}
@Serializable
@SerialName("Sort")
data class Sort (override val rubric: Rubric = Rubric(),
                 override val content: String = "",
                 override val possibilities: List<SortAnswer> = listOf(),
                 override val instruction: String = "",
                 val categories: List<SortCategory> = listOf()) : Activity(), GameAct
//    todo data class for more complex Acts
//    class Reorder () : Act()
//    class ColumnMatch : Act()


@Serializable
sealed class Answerable() : Send

@Serializable
@SerialName("Answer")
data class Answer (val answer: String = "",
                   val feedback: String = "",
                   val correct: Boolean = false) : Answerable()

@Serializable
@SerialName("SelectAnswer")
data class ResultAnswer(val choices: List<Answer> = listOf()) : Answerable()

@Serializable
@SerialName("SortAnswer")
data class SortAnswer (val answer: String = "",
                       val category: SortCategory = SortCategory()) : Answerable()

@Serializable
@SerialName("SortCategory")
data class SortCategory(val category: String = "",
                        val trueFeedback: String = "",
                        val falseFeedback: String = "") : Answerable()

