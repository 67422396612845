package prototype

import efas.common.UUID
import efas.common.objects.ContentType
import efas.common.objects.Send
import kotlin.reflect.KClass
import kotlin.reflect.KProperty

typealias Callback = ()->Unit

/**
 * Event bus actions for front-end
 */
sealed class EfasAction(val callback: Callback? = null)

/**
 * Actions that only affect local state; Handled by reducer
 */
sealed class LocalAction(callback: Callback? = null) : EfasAction(callback) {
    class Import(val item: Send, callback: Callback? = null) : LocalAction(callback)
    class Export(val type: ContentType, val newId: Boolean = false, callback: Callback? = null) : LocalAction(callback)
    data object Loading : LocalAction(null)
    data object Clear : LocalAction(null)

    class Add<T: Send>(val obj: T, callback: Callback? = null) : LocalAction(callback)
    class Replace<T: Send>(val obj: T, val index: Int? = null, callback: Callback? = null) : LocalAction(callback)
    class Delete<T: Send>(val index: Int, val marker: KClass<out T>, callback: Callback? = null) : LocalAction(callback) {
        companion object { inline operator fun<reified T: Send> invoke(index: Int) = Delete(index, T::class) }
    }
    class Change<T: Any?>(val prop: KProperty<T>, val value: T, callback: Callback? = null) : LocalAction(callback)
}

/**
 * Actions that require side effects and api usage; Handled by interceptor (thunk/middleware)
 */
sealed class ApiAction(callback: Callback?) : EfasAction(callback) {
    class Upload(val type: ContentType, val force: Boolean = false, callback: Callback? = null) : ApiAction(callback)
    class Download(val id: UUID, val type: ContentType, callback: Callback? = null) : ApiAction(callback)
    class Delete(val id: UUID, val type: ContentType, callback: Callback? = null) : ApiAction(callback)
    class Refresh(val type: ContentType, callback: Callback? = null) : ApiAction(callback)
    class Change<T: Any?>(val prop: KProperty<T>, val value: T, val id: UUID, val type: ContentType, callback: Callback? = null) : ApiAction(callback)
}