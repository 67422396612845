package efas.common.objects

import efas.common.Category
import efas.common.UUID
import efas.common.api.Client
import efas.common.api.InstantAsLong
import efas.common.baseURL
import kotlinx.datetime.Clock
import kotlinx.serialization.Serializable
import kotlin.collections.set


/**
 * Curriculum map data object definition
 */

val scoreRange: ClosedRange<Int> = 0..3
@Serializable
data class Progress (val progressMap: MutableMap<Category, Int> = Category.values().map { it to 0 }.toMap().toMutableMap(),
                     override val id: UUID = UUID.NIL) : Track {
    override var modified: InstantAsLong = Clock.System.now()

    companion object {
        inline operator fun <T : Any?> invoke(op: API.() -> T) : T = API.op()
    }

    object API : Client("$baseURL/progress")

    fun increment(skill: Category) {
        progressMap[skill]?.let {
            progressMap[skill] = (it + 1).coerceIn(scoreRange)
        } ?: throw Exception("[progress] invalid category input")
        initLastModified()
    }

    fun increment(skills: List<Category>) = skills.forEach { increment(it) }.also { initLastModified() }

    fun decrement(skill: Category) {
        progressMap[skill]?.let {
            progressMap[skill] = (it - 1).coerceIn(scoreRange)
        } ?: throw Exception("[progress] invalid category input")
        initLastModified()
    }

    fun decrement(skills: List<Category>) = skills.forEach { decrement(it) }.also { initLastModified() }

    operator fun set (skill: Category, level: Int) {
        progressMap[skill]?.let {
            progressMap[skill] = level.coerceIn(scoreRange)
        } ?: throw Exception("[progress] invalid category input")
    }

    fun set (data: Map<Category, Int>) = data.forEach { set(it.key, it.value) }

    private fun initLastModified () { modified = Clock.System.now() }
}
