package efas.common.api

import Base64
import Hex
import md5
import sha256

/**
 * Because of some weirdness with crypto-js's output, some extra conversions are used here
 */

actual object PlatformHash {

    actual fun String.sha256(): ByteArray = sha256(this)

    actual fun String.md5(): ByteArray = md5(this)
//
    actual fun ByteArray.toBase64(): String = Base64.stringify(Hex.parse(this.toHexString()))

    actual fun String.fromBase64(): ByteArray = Hex.stringify(Base64.parse(this)).toBytesRadix16()
}