package components

import Application.user
import MenuOption
import components.activity.Play
import components.activity.SkillChooser
import components.infrastructure.Menu
import components.onboarding.IntroComponent
import components.parts.Header
import components.song.Listen
import components.song.SelectSong
import hooks.useStore
import js.objects.jso
import react.FC
import react.Props
import react.router.Outlet
import react.router.RouteObject
import react.router.RouterProvider
import react.router.dom.createHashRouter
import react.router.useLocation
import remix.run.router.Router

val LoginGuard = FC<Props> {
    val state = useStore(user)
    val loc = useLocation()

    if (state.user == null) IntroComponent()
    else {
        Header {
            isClickable = loc.pathname != "/"
            color = state.colorScheme
        }
        Outlet()
    }
}

val Router: Router = createHashRouter(
    routes = arrayOf(
        jso {
            path = "/"
            Component = LoginGuard
            ErrorBoundary = Error
            children = arrayOf(
                jso {
                    path = "/"
                    Component = Menu
                },
                *MenuOption.values()
                    .map {
                        jso<RouteObject> {
                            path = it.path
                            Component = it.component
                        }
                    }.toTypedArray(),
                jso {
                    path = "/play"
                    Component = Play
                }
            )
        }
    )
)

val ApplicationUI = FC<Props> {
    RouterProvider {
        router = Router
    }
}
