package components.activity

import components.SlideDeck
import components.parts.Button
import components.parts.Footer
import components.parts.ViewState
import efas.common.EfasColor
import emotion.css.cx
import emotion.react.css
import react.FC
import react.Props
import react.dom.events.MouseEventHandler
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.header
import react.dom.html.ReactHTML.p
import react.useState
import style.Layout
import style.arrowLeft
import style.handPoint
import web.cssom.*
import web.cssom.Auto.Companion.auto

external interface HelpProps : Props {
    var color: EfasColor?
    var onBack: MouseEventHandler<*>
}

val GameHelp = FC<HelpProps> { props ->
    val helpState = useState(0)
    div {
        className = cx(Layout.columnFull, ClassName("layout_fill"))
        div {
            className = cx(ClassName("header"), Layout.defaultPadding)
            Button.Circle {
                icon = arrowLeft
                color = props.color
                onClick = props.onBack
                css {
                    gridColumn = integer(1)
                    paddingBottom = 4.rem
                    marginRight = auto
                }
            }
            header {
                +"HELP"
                css { gridColumn = integer(2) }
            }
        }

        div {
            className = Layout.innerFull
            p { +"Click on each button below to see what it does"}
            SlideDeck {
                color = props.color
                content = mapOf(
                    "Go Back" to "Go to previous game",
                    "Instructions" to "See information about the current game",
                    "Help" to "You are here",
                    "Listen" to "You can listen to the song with lyrics at any time",
                    "Skip" to "Skip forward to the next game"
                )
                indicate = true
                index = helpState
            }
        }
        div {
            css(Layout.rowLeft, Layout.defaultPadding) {
                width = 90.pct
            }
            handPoint.component {
                fill = props.color?.shadow ?: EfasColor.BLACK
                css {
                    transform = rotate(180.deg)
                    marginLeft = (helpState.component1() * 23).pct
                }
            }
        }
        Footer {
            color = props.color
            onBack = { helpState.component2()(0) }
            onInstruction = { helpState.component2()(1) }
            onHelp = { helpState.component2()(2) }
            onListen = { helpState.component2()(3) }
            onForward = { helpState.component2()(4) }
        }
    }
}