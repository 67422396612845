
import components.ApplicationUI
import efas.common.EfasColor
import interceptors.fromLocal
import interceptors.localMiddleware
import kotlinx.browser.window
import model.user.UserState
import model.user.userRootReducer
import org.reduxkotlin.applyMiddleware
import org.reduxkotlin.threadsafe.createThreadSafeStore
import prototype.EfasCore
import react.create
import react.dom.client.createRoot
import web.dom.document
import web.html.HTML.div

object Application : EfasCore() {
    val user = createThreadSafeStore(
        ::userRootReducer,
        fromLocal(window.localStorage, UserState.serializer()) ?: UserState(),
        applyMiddleware(localMiddleware(window.localStorage, UserState.serializer()))
    )
}

fun main() {
    kotlinext.js.require<Unit>("./style/main.scss")
    startLogs()
    kotlinx.browser.document.bgColor = EfasColor.WHITE

    val root = document.createElement(div)
        .apply { id = "root" }
        .let { document.body.appendChild(it) }

    createRoot(root)
        .render(ApplicationUI.create())
}

