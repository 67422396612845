package efas.common.objects

import efas.common.UUID
import kotlinx.datetime.Instant
import kotlin.reflect.KClass


/**
 * Send: Allow object to be sent via [efas.common.api.Message]
 *
 *      common interface to facilitate polymorphic serialization
 */
interface Send
/**
 * Save: add uid for savable objects
 */
interface Save : Send { val id: UUID }

/**
 * Track: for objects that keep track of their last modified time
 */
interface Track : Save { val modified: Instant }

/**
 * Public: public visibility configuration for objects
 */
interface Public : Track {
    val isActive: Boolean
    val name: String?
}

/**
 * Interface to separate main content objects [Test], [Game] and [Song] from less public objects like [User] and [MetaData]
 */
sealed interface Content : Public

enum class ContentType(val marker: KClass<out Content>) {
    SONG(Song::class),
    GAME(Game::class),
    TEST(Test::class),
}

/**
 * Activities to be treated as test questions
 *
 */
sealed interface TestAct

/**
 * Activities to be treated as game activities
 *
 */
sealed interface GameAct

