package components

import components.parts.*
import efas.common.EfasColor
import emotion.css.cx
import emotion.react.css
import js.errors.JsError
import react.FC
import react.Props
import react.PropsWithClassName
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.header
import react.dom.html.ReactHTML.p
import react.router.useNavigate
import react.router.useRouteError
import style.*
import web.cssom.ClassName
import web.cssom.Color
import web.cssom.rem
import web.cssom.vh
import web.prompts.alert

external interface ErrorProps : Props {
    var message: String?
}

val Error = FC<ErrorProps> {props ->
    val error = useRouteError() as? JsError?
    div {
        className = cx(Layout.columnEven, ClassName("font_center"), ClassName("layout_fill"))
        header {
            +"ERROR"
        }
        icon("exclamation-triangle") {
            css {
                fontSize = 15.vh
                color = Color(EfasColor.YELLOW.primary)
            }
        }
        h3 {
            +"Please try again in a few moments, if the issue persists contact a developer"
        }
        p { +(error?.message ?: props.message ?: "") }
    }
}