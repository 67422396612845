package efas.common
import kotlinx.serialization.Serializable

/**
 * Enumeration of possible categories (and their respective categories)
 */

@Serializable
enum class Category(val group: SkillGroup, val explanation: String) {
//    Grammar
    COMMON_NOUNS(SkillGroup.Grammar, "A person, place, thing, idea, feeling.  Examples: child, street, book, law, love."),
    PROPER_NOUNS(SkillGroup.Grammar, "A specific person, place, thing—always capitalized.  Examples: Drake, Fifth Avenue, American Constitution, Twilight, Cupid."),
    SUBJECT(SkillGroup.Grammar, "Tells who or what a sentence is about. Example: Carmen is studying English."),
    SUBJECT_PRONOUNS(SkillGroup.Grammar, "Takes the place of a subject noun:  I, you, she, he, we, they, it Example:  She loves English. It isn’t difficult."),
    DIRECT_OBJECT(SkillGroup.Grammar,"Answers the question “what?” after a verb.  Example: Carmen is studying English."),
    OBJECT_PRONOUNS(SkillGroup.Grammar, "Takes the place of an object noun. Me, you, her, him, us, them, it.  Example:  Brian likes me but he adores her."),
    POSSESSIVE_PRONOUNS(SkillGroup.Grammar,"Shows ownership: My, mine, your, yours, his, her, hers, its.  Example: Don’t touch his book. It isn’t yours."),
    VERBS(SkillGroup.Grammar,"Action words that describe what the subject is doing, saying, thinking, feeling.  Examples: to sing, to write, to smile, to delay."),
    VERB_TENSES(SkillGroup.Grammar,"Verbs have different tenses that express present, past, or future actions.  Present: I am hungry.\n" +
            "Past: She ate lunch.\n" +
            "Future: They will eat pizza."),
    HELPING_VERBS(SkillGroup.Grammar,"Verbs that “help” or change the meaning  or time frame of  a main verb.  Examples: I do [main verb] my homework regularly. Helping verbs:  I can do it; I might do it; I should do it.  See how the meaning changes with each helper?"),
    ADJECTIVES(SkillGroup.Grammar,"A modifying word that adds information to nouns and pronouns. Examples:  The new student is helpful.  Even better, he is cute."),
    ADVERBS(SkillGroup.Grammar, "A modifying word that adds information to verbs and adjectives.  Examples: That new student belched loudly.  He was very embarrassed."),
    CONJUNCTIONS(SkillGroup.Grammar, "Words that join other words or parts of a sentence: and, or, but, neither..nor, yet. Example: I love rock and roll but I don’t care for jazz!"),
    PREPOSITIONS(SkillGroup.Grammar, "Shows the relationship of a noun or pronoun to another word in a sentence: on, with, out, in, of, for, to, between.  Example: They went to the club on Wednesday night with a few friends."),
    CONTRACTIONS(SkillGroup.Grammar, "A way to shorten a word by leaving out a letter or letters and replacing them with an apostrophe.  Examples: Let us go = Let’s go.  It is late = It’s late.  They are here = They’re here."),
//    TODO("ask Lora to write one")
    INTERJECTIONS(SkillGroup.Grammar, ""),
//    Sentences
    SUBJECTS_AND_PREDICATES(SkillGroup.Sentences,"Sentences have two main parts: the subject (who or what the sentence is about) and the predicate (what the subject does, says, thinks, and more).  Example: The punk rock guitarist smashed his instrument."),
    SUBJECT_VERB_AGREEMENT(SkillGroup.Sentences,"The subject (noun/pronoun) of a sentence must match the correct form of the verb in the predicate.  Right: I am. Wrong: I is. Right: He sings. Wrong: He sing. Right: They are well-dressed.  Wrong: They be well-dressed."),
    DECLARATIVE(SkillGroup.Sentences,"States something.  Ends with a period (full stop). Example: Rihanna is my favorite performer."),
    INTERROGATIVE(SkillGroup.Sentences,"Asks something.  Ends with a question mark. Example:  Who is your favorite performer?"),
    IMPERATIVE(SkillGroup.Sentences,"Demands something. Usually ends with an exclamation point. Example: Listen to this!"),
    // TODO("ask Lora to write one; isn't this covered by interjection?")
    EXCLAMATION(SkillGroup.Sentences, ""),
    PUNCTUATION(SkillGroup.Sentences,"Marks that help separate different thoughts in a sentence or show where the sentence ends.  Periods or full stops (.) end sentences. Commas (,) separate lists in a sentence or different parts of the sentence."),
    CLAUSES(SkillGroup.Sentences, "An independent clause = a sentence. It can stand on its own.  It can also use a conjunction to hook up with another independent clause.  Example: I love pizza. I love pizza and I love tacos too.\n" +
            "A dependent clause needs to be attached to an independent clause to make sense.  Example:  Ever since I was a child, I have always loved pizza. (Ever since I was a child needs more information to complete the sentence.)"),
//    Vocabulary
    DEFINITIONS(SkillGroup.Vocabulary,"A word’s meaning.  Many words have different definitions and different parts of speech. Example:  Dance (noun) = a movement to music; a social gathering, an art form.  Dance (verb) = to twist and twerk!"),
    SYNONYMS(SkillGroup.Vocabulary,"Different words that have the same meaning. Example: hot, boiling, steamy. "),
    ANTONYMS(SkillGroup.Vocabulary,"Different words that have an opposite meaning. Example:  hot/cold; run/walk. "),
    HOMONYMS(SkillGroup.Vocabulary,"Words that sound the same but have different meanings and spellings.  Example: here/hear; sweet/suite."),
    COMPOUND(SkillGroup.Vocabulary,"A word made up of two or more words stuck together. Example: ball + park = ballpark; butter + fly = butterfly.  "),
    PREFIXES(SkillGroup.Vocabulary,"A letter or letters added to the front of a word that changes its meaning.  Examples: re- is a prefix meaning “again.” Re- +use= reuse.   Mis- is a prefix that can mean “badly.”  Mis-+behave= misbehave."),
    SUFFIXES(SkillGroup.Vocabulary,"A letter or letters added to the end of a word that changes its meaning.  Examples: -less is a suffix meaning “without.”   Hope + \n" +
            "-less = hopeless; -ful is a suffix meaning “full of.”  Thank + ful = thankful."),
    ROOT_WORDS(SkillGroup.Vocabulary,"The basic, meaningful  part of a word, without a prefix or suffix.  Example:  legal is a root word meaning “relating to the law.” Add the prefix ill- and you get illegal (not legal). Add the suffix –ese and you get “legalese” (legal language)."),
//    Comprehension
    COMPREHENSION(SkillGroup.Comprehension,"Make sure you are clear about the meaning of all words or phrases. Check definitions. Reading the words as you listen helps with comprehension."),
    FOLLOWING_INSTRUCTIONS(SkillGroup.Comprehension,"If at first you don’t understand an explanation or direction, read it again. Look up unfamiliar words.  Look for context clues."),
    TIMELINE_COMPREHENSION(SkillGroup.Comprehension,"Look for the ways a text tells a story or makes an argument.  One idea should logically lead to another.  Logical: I ate too much and got sick.  Not really logical:  I got sick and ate too much. "),
    CONTEXT_CLUES(SkillGroup.Comprehension,"Hints that explain a strange word, name or idea.  Example:  T. Rex, a large meat-eating dinosaur, crashed through the brush.  \n" +
            "(The context clues to the meaning of T.Rex are right next to the name. )"),
    IDIOMS(SkillGroup.Comprehension,"A group of words that mean something else than their literal meaning.  Examples: it’s raining cats and dogs; that’s a piece of cake!"),
    METAPHORS(SkillGroup.Comprehension,"A poetic figure of speech that compares two unlike objects directly.  Example:  Allan is a real devil."),
    SIMILES(SkillGroup.Comprehension,"A figure of speech that compares of two objects using “like” or “as.”  Example: She acts like a baby when she loses the game; Juan is as broad as a truck."),
    MAIN_IDEA(SkillGroup.Comprehension,"Imagine an article about the emotional support pets give.  The main idea is how pets comfort owners.  Details might list diffreent types of pets."),
    BACKGROUND_KNOWLEDGE(SkillGroup.Comprehension,"Information that you already know (or need to find out about) in order to understand the subject of a text or article. ")
    ;

    fun toHrString(): String = this.name.normalizeText()

    enum class SkillGroup {
        Grammar, Sentences, Vocabulary, Comprehension
    }

    companion object {
        /**
         * List of all skills in human readable format
         */
        fun getAllSkillNames () : List<String> = values().map { it.toString() }

        /**
         * List of skills in This Format broken into subcategories
         */
        fun getSeparatedSkillNames () : Map<String, List<String>> =
            SkillGroup.values().map { group ->
                group.name to values().filter { it.group == group }.map { it.toString() }
            }.toMap()


        fun String.normalizeText() : String = this
            .replace("_", " ")
            .lowercase()
            .capitalize()
    }

}