package efas.common.objects

import efas.common.UUID
import efas.common.api.Client
import efas.common.api.InstantAsLong
import efas.common.baseURL
import kotlinx.datetime.Clock
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("Game")
data class Game (override val id: UUID = UUID.NIL,
                 override val name: String? = null,
                 val song: UUID? = null,
                 val prompt: String? = null,
                 val activities: List<Activity> = listOf(),
                 val rubric: Rubric? = null,
                 override val isActive: Boolean = false,
                 override val modified: InstantAsLong = Clock.System.now()) : Content {

    companion object {
        inline operator fun <T : Any?> invoke(op: API.() -> T) : T = API.op()
    }

    object API : Client("$baseURL/games")

 }