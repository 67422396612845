package components.infrastructure

import Application.user
import MenuOption
import components.parts.Button
import components.parts.Header
import efas.common.EfasColor
import emotion.react.css
import hooks.useStore
import kotlinx.css.header
import react.FC
import react.Props
import react.dom.h1
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.header
import react.fc
import react.router.useNavigate
import style.Layout
import styled.css
import styled.styledDiv
import web.cssom.ClassName
import web.cssom.vh

val Menu = FC<Props> {
    val navigate = useNavigate()

    div {
        className = Layout.innerEven
        header { +"MAIN MENU" }
        div {
            className = Layout.menuGrid
            MenuOption.values().forEachIndexed { i, item ->
                Button.Menu {
                    color = EfasColor.values()[i]
                    text = item.text
                    icon = item.icon
                    onClick = { navigate(item.path) }
                }
            }
        }
    }
}