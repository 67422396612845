package style

import web.cssom.ClassName

/**
 * bindings to [_layout.scss]
 */
object Layout { 
    val pageEven = ClassName("layout_page_even")
    val pageFull = ClassName("layout_page_full")
    
    val column = ClassName("layout_column")
    val columnCenter = ClassName("layout_column_center")
    val columnEven = ClassName("layout_column_even")
    val columnFull = ClassName("layout_column_full")
    val columnLeft = ClassName("layout_column_left")
    val columnRight = ClassName("layout_column_right")

    val row = ClassName("layout_row")
    val rowCenter = ClassName("layout_row_center")
    val rowEven = ClassName("layout_row_even")
    val rowFull = ClassName("layout_row_full")
    val rowLeft = ClassName("layout_row_left")
    val rowRight = ClassName("layout_row_right")

    val defaultPadding = ClassName("layout_default_padding")
    val fill = ClassName("layout_fill")
    val container = ClassName("layout_container")
    val scrollContainer = ClassName("layout_scroll_container")
    val innerFull = ClassName("layout_inner_full")
    val innerEven = ClassName("layout_inner_even")
    val innerLeft = ClassName("layout_inner_left")
    val columnTableau = ClassName("layout_column_tableau")
    val menuGrid = ClassName("layout_menu_grid")
    val scroll = ClassName("layout_scroll")
}