package components

import components.parts.Button
import components.parts.Button.solidColor
import efas.common.Category
import efas.common.Category.Companion.normalizeText
import efas.common.EfasColor
import emotion.css.cx
import emotion.react.css
import hooks.useDialog
import js.core.n
import react.*
import react.dom.events.MouseEventHandler
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.h4
import react.dom.html.ReactHTML.p
import style.Layout
import style.circleEmpty
import style.circleFilled
import style.handPoint
import web.cssom.*

external interface SlideDeckProps: PropsWithClassName {
    var content: Map<String, String>
    var color: EfasColor?
    var onSkip: MouseEventHandler<*>?
    var skills: List<Category>?
    var indicate: Boolean?
    var index: StateInstance<Int>?
}

val SlideDeck = FC<SlideDeckProps> {props ->
    var index by props.index ?: useState<Int>(0)
    var catFocus by useState<Category?>(null)

    div {
        className = cx(Layout.innerEven)

        h1 {
            className = Layout.defaultPadding
            +props.content.keys.elementAt(index)
        }
        p {
            className = Layout.defaultPadding
            +props.content.values.elementAt(index)
        }
        if (props.indicate != true) div {
            css(Layout.rowEven, Layout.defaultPadding) {
                width = 50.pct
            }
            if (props.content.size > 1) repeat(props.content.size) {
                if (it == index) circleFilled.component {
                    fill = props.color?.shadow ?: EfasColor.BLACK
                    key = "carousel-$it"
                }
                else circleEmpty.component {
                    fill = props.color?.shadow ?: EfasColor.BLACK
                    key = "carousel-$it"
                    onClick = { _ -> index = it }
                }
            }
        }

        catFocus?.let { cat ->
            div {
                css(Layout.columnLeft, ClassName("display_item_card"), ClassName("shadow_out")) {
                    backgroundColor = Color(props.color?.highlight ?: EfasColor.GREY.highlight)
                }
                h1 {
                    css {
                        color = Color(props.color?.shadow ?: EfasColor.GREY.shadow)
                    }
                    +cat.name.normalizeText()
                }
                if(!cat.group.name.equals(cat.name, true)) h4 {
                    +cat.group.name
                }
                p {
                    +cat.explanation
                }
            }
        }

        props.skills?.forEach { cat ->
            Button.Small {
                selected = catFocus == cat
                color = props.color
                text = cat.name.normalizeText()
                onClick = { if ( catFocus == cat) catFocus = null else catFocus = cat }
            }
        }

        props.onSkip?.let {
            Button.Large {
                color = props.color
                text = "Skip"
                onClick = it
            }
        }
    }
}