package efas.common.objects

import efas.common.UUID
import efas.common.api.PlatformHash.fromBase64
import efas.common.api.PlatformHash.sha256
import efas.common.api.PlatformHash.toBase64
import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline

@JvmInline
@Serializable
value class Password(val value: ByteArray) {
    constructor(value: String) : this(value.fromBase64())

    companion object {
        fun fromRawString(str: String, id: UUID) : Password = Password("$id:$str:englishforasong".sha256())
        val NIL = Password(ByteArray(0))
    }

    override fun toString(): String = value.toBase64()
}
