package components.parts

import efas.common.EfasColor
import emotion.react.css
import hooks.useStore
import react.FC
import react.PropsWithClassName
import react.dom.events.MouseEventHandler
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.router.useNavigate
import style.hamburger
import web.cssom.ClassName
import web.cssom.Color
import web.cssom.integer
import web.cssom.rem

external interface HeaderProps : PropsWithClassName {
    var isClickable: Boolean?
    var color: EfasColor?
    var onClick: MouseEventHandler<*>?
}

val Header = FC<HeaderProps> {
    val nav = useNavigate()
    div {
        css(ClassName("header"), it.className) {
            backgroundColor = Color(it.color?.primary ?: EfasColor.GREEN.primary)
        }

        if (it.isClickable == true) div {
            css(ClassName("layout_row_left")) {
                gridColumn = integer(1)
            }
            hamburger.component {
                css { margin = 0.5.rem }
            }
            if (it.isClickable == true) {
                onClick = (it.onClick ?: { nav("/") })
            }
        }

        img {
            src = "images/EnglishForASong.png"
            css {
                gridColumn = integer(2)
                height = 4.5.rem
                color = Color(it.color?.shadow ?: EfasColor.GREEN.shadow)
            }
        }
    }
}