package prototype

import efas.common.objects.Game
import efas.common.objects.Inventory
import efas.common.objects.Song
import efas.common.objects.Test

fun rootReducer(state: State, action: Any): State =
    if (action is LocalAction) when(action) {
        LocalAction.Clear -> State()
        LocalAction.Loading -> state.copy(loading = !state.loading)
        is LocalAction.Import -> when(val new = action.item) {
            is Song -> state.copy(song = state.song.copy(item = new))
            is Game -> state.copy(game = state.game.copy(item = new))
            is Test -> state.copy(test = state.test.copy(item = new))
            is Inventory -> when(new.manifest.firstOrNull()) {
                is Song -> state.copy(song = state.song.copy(inventory = new.manifest as List<Song>))
                is Game -> state.copy(game = state.game.copy(inventory = new.manifest as List<Game>))
                is Test -> state.copy(test = state.test.copy(inventory = new.manifest as List<Test>))
                else -> state
            }
            else -> state
        }
        else -> state
    }
    else state
