package components.infrastructure

import Application
import Application.store
import Application.user
import components.parts.Header
import efas.common.EfasColor
import emotion.react.css
import hooks.useStore
import prototype.LocalAction
import react.FC
import react.Props
import react.dom.html.ReactHTML.datalist
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.header
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.option
import style.Layout
import web.cssom.ClassName
import web.cssom.Overflow
import web.cssom.pct
import web.html.InputType


val SettingsComponent = FC<Props> {
    val state = useStore(store)
    val user = useStore(user)
    div {
        className = Layout.innerFull

        header { +"SETTINGS" }

        div {
            css(Layout.innerLeft) {
                width = 100.pct
            }
            label {
                htmlFor = "theme_picker"
                +"COLOR THEME:"
            }
            datalist {
                id = "efas_colors"
                EfasColor.values().forEach {
                    option { +it.primary }
                }
            }
            input {
                type = InputType.color
                defaultValue = user.colorScheme.primary
                list = "efas_colors"
                id = "theme_picker"
                onChange = { e ->
                    EfasColor.getByColorString(e.target.value)
                        ?.let { Application.user.dispatch(LocalAction.Replace(it)) }
                }
            }
        }
    }
}
