package components.song

import components.parts.Button
import efas.common.EfasColor
import emotion.react.css
import hooks.useAudio
import react.FC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.p
import react.router.useNavigate
import style.Layout
import style.pause
import style.play
import web.cssom.*
import web.cssom.BackgroundSize.Companion.cover
import kotlin.time.Duration.Companion.seconds

val SongItem = FC<SongProps> {props ->
    val (audio,isPlayingState) = useAudio(props.song, 10.seconds)
    var isPlaying by isPlayingState
    val nav = useNavigate()

    div {
        css(Layout.rowLeft, ClassName("display_item_card"), ClassName("shadow_out")) {
            backgroundColor = Color(EfasColor.WHITE)
            active {
                backgroundColor = Color((props.color ?: EfasColor.BLUE).primary)
            }
        }
        Button.Square {
            icon = if (!isPlaying) play else pause
            onClick = {
                if(!isPlaying) {
                    audio.current?.let {
                        props.onPlay(it)
                        it.play()
                    }
                }
                else audio.current?.pause()
                isPlaying = !isPlaying
                it.preventDefault()
                it.stopPropagation()
            }
            css {
                backgroundImage = url("${props.song?.imageURL}")
                backgroundSize = cover
                height = 7.5.rem
                width = 7.5.rem
            }
        }

        div {
            css { color = Color(EfasColor.BLACK) }
            h2 {
                +(props.song?.title ?: "")
            }
            p {
                +(props.song?.artist ?: "")
            }
        }

        onClick = { props.song?.let { nav("/play?song=${it.id}") }  }
    }
}