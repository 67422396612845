package efas.common.objects

import efas.common.*
import efas.common.api.InstantAsLong
import efas.common.api.TypeAsString
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Metadata transport object
 *
 * @property id
 * @property modified
 * @property hash
 * @property dataType
 */

@Serializable
@SerialName("MetaData")
data class MetaData(override val id: UUID,
                    override val name: String? = null,
                    override val modified: InstantAsLong = Clock.System.now(),
                    override val isActive: Boolean = false,
                    val dataType: TypeAsString) : Public {
    companion object {
        inline operator fun <reified T : Content>invoke(id: UUID, name: String?, lastModified: Instant, active: Boolean = false, hash: Int? = null, ) : MetaData =
            MetaData(id, name, lastModified, active, T::class)

        inline operator fun <reified T : Content>invoke(id: UUID) : MetaData = MetaData(id, dataType = T::class)
    }

}

/**
 * Metadata object for negotiating file transfer
 *
 * The terms of the transfer are negotiated over the REST api,
 * then each side can refer to their respective locally stored FileTransfer object
 * by an assigned token when communicating over the transfer port
 *
 * @property name
 * @property size
 * @property checksum
 */


/**
 * list of partially empty api.objects for assessing media asset state
 *
 * @property manifest
 */
@Serializable
@SerialName("Inventory")
data class Inventory(val manifest: List<Send>) : Send

/**
 * Wrapper to guarantee metadata on local storage
 *
 * @property obj
 * @property modified
 */
@Serializable
data class Storage(val obj: Send,
                   val modified: Instant = Clock.System.now()) : Send {
    val hash: Int = obj.hashCode()
}
