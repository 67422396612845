package efas.common.objects

import efas.common.UUID
import efas.common.api.Client
import efas.common.api.DurationAsLong
import efas.common.api.InstantAsLong
import efas.common.baseURL
import kotlinx.datetime.Clock
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
@SerialName("Song")
data class Song (override val id: UUID = UUID.NIL,
                 val title: String = "",
                 val artist: String = "",
                 val album: String = "",
                 val lyrics: List<Lyric> = listOf(),
                 override var isActive: Boolean = false,
                 val audioURL: String? = null,
                 val imageURL: String? = null,
                 override val modified: InstantAsLong = Clock.System.now()) : Content {

    override val name: String = title
    companion object {
        inline operator fun <T : Any?> invoke(op: API.() -> T) : T = API.op()
    }
    object API : Client("$baseURL/songs")
}

@Serializable
data class Lyric (val text: String,
                  var timeIn: DurationAsLong? = null,
                  var timeOut: DurationAsLong? = null) : Send