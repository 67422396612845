package hooks
import efas.common.objects.Song
import react.*
import react.dom.events.ReactEventHandler
import react.dom.html.ReactHTML.audio
import web.html.HTMLAudioElement
import web.timers.Timeout
import web.timers.clearTimeout
import web.timers.setTimeout
import kotlin.time.Duration

fun ChildrenBuilder.useAudio(song: Song?,
                             pauseAfter: Duration? = null,
                             onTimeUpdate: ReactEventHandler<HTMLAudioElement>? = null)
: Pair<MutableRefObject<HTMLAudioElement>, StateInstance<Boolean>> {

    val player = useRef<HTMLAudioElement>()
    val isPlaying = useState(false)
    var timeout by useState<Timeout>()

    useEffect(player.current) {
        player.current?.onplay = {
            isPlaying.component2().invoke(true)
            pauseAfter?.let {
                timeout = setTimeout(it) {
                    player.current?.pause()
                    isPlaying.component2().invoke(false)
                }
            }
        }
        player.current?.onpause = {
            clearTimeout(timeout)
            isPlaying.component2().invoke(false)
        }
    }

    song?.let {
        audio {
            src = it.audioURL ?: ""
            controls = false

            ref = player
            id = it.hashCode().toString()
            this.onTimeUpdate = onTimeUpdate
        }
    }

    return player to isPlaying
}

fun ChildrenBuilder.useAudioWithClock(song: Song?)
: Triple<MutableRefObject<HTMLAudioElement>, StateInstance<Boolean>, StateInstance<Double>> {
    val clock = useState(0.0)
    val (audio, isPlaying) = useAudio(song) {
        clock.component2().invoke(it.currentTarget.currentTime)
    }
    return Triple(audio, isPlaying, clock)
}
