package efas.common.api

import efas.common.baseURL
import efas.common.objects.Send
import efas.common.objects.User
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.http.*
import kotlinx.serialization.Serializable

@Serializable
data class Token(val token: String): Send {
   companion object : LocalProvider() {
       var realm = "user"
       suspend fun fromIssuer(): Token? {
           val res = Client.currentUser?.let {
               Client.client.post("$baseURL/auth/$realm") {
                   contentType(ContentType.Application.Json)
                   setBody<User>(it)
               }.body<Message>()
           }
           return when (res) {
               is Content<*> -> res.obj as? Token
               else -> null
           }?.also(::save)
       }
   }

}

fun HttpMessageBuilder.header(token: Token): HttpMessageBuilder {
    if (this.headers.contains(HttpHeaders.Authorization)) headers.remove(HttpHeaders.Authorization)
    this.header(HttpHeaders.Authorization, "Bearer ${token.token}")
    return this
}

expect abstract class LocalProvider() {
    fun fromLocal() : Token?
    fun save(tk: Token)
}
